import React from 'react'

import {
  fetchAsynchronousTask,
  fetchAsynchronousTasks,
  postDataIntegrationsCarAllowances,
  postDataIntegrationsLeaveRegistrations,
  postDataIntegrationsLeaveRegistrationsExport,
  postDataIntegrationsPayRoll,
  postDataIntegrationsReimbursements,
  putDataIntegrationsLeaveRegistrationsExport,
} from '../api/asynchronous-tasks'
import { initiateSavingStagedImportData, initiateStagedImport } from '../api/data'
import { postDataIntegrationsEmployees } from '../api/data-integration'
import ActionTypes from '../constants/action-types'
import AsynchronousTask from '../model/asynchronousTask'
import { ExternalPayRollImportMode } from '../model/dataIntegration'
import { OneTimePayType } from '../model/oneTimePay'
import { AsynchronousTaskAction } from '../reducers/asynchronousTasks'
import PreferredTaxCardType from '../types/preferred-tax-card-type'
import { isRequestError } from '../utils/error-utils'
import { getCompanyID, getStateSignature } from '../utils/reducer-utils'
import { PromiseVoid } from '../utils/request-utils'

function loadingAsynchronousTasks(companyID: string): AsynchronousTaskAction {
  return {
    type: ActionTypes.ASYNCHRONOUS_TASK_LOADING,
    companyID,
  }
}
function loadedAsynchronousTasks(companyID: string, asynchronousTasks: AsynchronousTask[]): AsynchronousTaskAction {
  return {
    type: ActionTypes.ASYNCHRONOUS_TASK_LOADED,
    companyID,
    asynchronousTasks,
  }
}
function failedLoadingAsynchronousTasks(companyID: string, error: Error): AsynchronousTaskAction {
  return {
    type: ActionTypes.ASYNCHRONOUS_TASK_LOAD_FAILED,
    companyID,
    error,
  }
}

function startAsynchronousTask(companyID: string): AsynchronousTaskAction {
  return {
    type: ActionTypes.ASYNCHRONOUS_TASK_START,
    companyID,
  }
}

export function savedAsynchronousTask(companyID: string, asynchronousTask: AsynchronousTask): AsynchronousTaskAction {
  return {
    type: ActionTypes.ASYNCHRONOUS_TASK_SAVED,
    companyID,
    asynchronousTask,
  }
}

function failedStartingAsynchronousTask(companyID: string, error: Error): AsynchronousTaskAction {
  return {
    type: ActionTypes.ASYNCHRONOUS_TASK_START_FAILED,
    companyID,
    error,
  }
}

export function getAsynchronousTasks(companyID: string) {
  return (dispatch: React.Dispatch<any>): Promise<AsynchronousTask[] | void> => {
    dispatch(loadingAsynchronousTasks(companyID))
    return fetchAsynchronousTasks(companyID)
      .then((res) => {
        dispatch(loadedAsynchronousTasks(companyID, res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedLoadingAsynchronousTasks(companyID, e))
        }
      })
  }
}

export function getAsynchronousTask(asynchronousTaskID: string) {
  return (dispatch: React.Dispatch<any>, getState?: getStateSignature): Promise<AsynchronousTask | void> => {
    const companyID = getCompanyID(getState)
    if (!companyID) {
      return PromiseVoid
    }
    dispatch(loadingAsynchronousTasks(companyID))
    return fetchAsynchronousTask(asynchronousTaskID)
      .then((res) => {
        dispatch(savedAsynchronousTask(companyID, res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedLoadingAsynchronousTasks(companyID, e))
        }
      })
  }
}

export function startPayRollImport(companyID: string, salaryPeriodID: string, mode: ExternalPayRollImportMode) {
  return (dispatch: React.Dispatch<any>): Promise<AsynchronousTask | void> => {
    dispatch(startAsynchronousTask(companyID))
    return postDataIntegrationsPayRoll(companyID, salaryPeriodID, mode)
      .then((res) => {
        dispatch(savedAsynchronousTask(companyID, res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedStartingAsynchronousTask(companyID, e))
        }
      })
  }
}

export function startLeaveRegistrationImport(companyID: string, salaryPeriodID: string) {
  return (dispatch: React.Dispatch<any>): Promise<AsynchronousTask | void> => {
    dispatch(startAsynchronousTask(companyID))
    return postDataIntegrationsLeaveRegistrations(companyID, salaryPeriodID)
      .then((res) => {
        dispatch(savedAsynchronousTask(companyID, res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedStartingAsynchronousTask(companyID, e))
        }
      })
  }
}

export function startLeaveRegistrationExport(companyID: string) {
  return (dispatch: React.Dispatch<any>): Promise<AsynchronousTask | void> => {
    dispatch(startAsynchronousTask(companyID))
    return postDataIntegrationsLeaveRegistrationsExport(companyID)
      .then((res) => {
        dispatch(savedAsynchronousTask(companyID, res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedStartingAsynchronousTask(companyID, e))
        }
      })
  }
}

export function saveLeaveRegistrationExport(
  companyID: string,
  asynchronousTaskID: string,
  timeRegistrationIDs: string[]
) {
  return (dispatch: React.Dispatch<any>): Promise<AsynchronousTask | void> => {
    dispatch(startAsynchronousTask(companyID))
    return putDataIntegrationsLeaveRegistrationsExport(asynchronousTaskID, timeRegistrationIDs)
      .then((res) => {
        dispatch(savedAsynchronousTask(companyID, res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedStartingAsynchronousTask(companyID, e))
        }
      })
  }
}

export function startEmployeeImport(companyID: string, preferredTaxCard: PreferredTaxCardType) {
  return (dispatch: React.Dispatch<any>): Promise<AsynchronousTask | void> => {
    dispatch(startAsynchronousTask(companyID))
    return postDataIntegrationsEmployees(companyID, preferredTaxCard)
      .then((res) => {
        dispatch(savedAsynchronousTask(companyID, res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedStartingAsynchronousTask(companyID, e))
        }
      })
  }
}

export function startReimbursementImport(companyID: string, salaryPeriodID: string) {
  return (dispatch: React.Dispatch<any>): Promise<AsynchronousTask | void> => {
    dispatch(startAsynchronousTask(companyID))
    return postDataIntegrationsReimbursements(companyID, salaryPeriodID)
      .then((res) => {
        dispatch(savedAsynchronousTask(companyID, res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedStartingAsynchronousTask(companyID, e))
        }
      })
  }
}

export function startCarAllowanceImport(companyID: string, salaryPeriodID: string) {
  return (dispatch: React.Dispatch<any>): Promise<AsynchronousTask | void> => {
    dispatch(startAsynchronousTask(companyID))
    return postDataIntegrationsCarAllowances(companyID, salaryPeriodID)
      .then((res) => {
        dispatch(savedAsynchronousTask(companyID, res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedStartingAsynchronousTask(companyID, e))
        }
      })
  }
}

export function storeStagedImportData(asynchronousTaskID: string, employeeImportIDs: string[] = []) {
  return (dispatch: React.Dispatch<any>, getState?: getStateSignature): Promise<AsynchronousTask | void> => {
    const companyID = getCompanyID(getState)
    if (!companyID) {
      return PromiseVoid
    }

    dispatch(startAsynchronousTask(companyID))
    return initiateSavingStagedImportData(asynchronousTaskID, employeeImportIDs)
      .then((res) => {
        dispatch(savedAsynchronousTask(companyID, res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedStartingAsynchronousTask(companyID, e))
        }
      })
  }
}

export function startExcelEmployeesData(fileID: string) {
  return (dispatch: React.Dispatch<any>, getState?: getStateSignature): Promise<AsynchronousTask | void> => {
    const companyID = getCompanyID(getState)
    if (!companyID) {
      return PromiseVoid
    }

    const importType = 'ExcelEmployees'
    dispatch(startAsynchronousTask(companyID))
    return initiateStagedImport(companyID, importType, fileID)
      .then((res) => {
        dispatch(savedAsynchronousTask(companyID, res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedStartingAsynchronousTask(companyID, e))
        }
      })
  }
}

export function startOneTimePaysImport(fileID: string, oneTimePayType?: OneTimePayType) {
  return (dispatch: React.Dispatch<any>, getState?: getStateSignature): Promise<AsynchronousTask | void> => {
    const companyID = getCompanyID(getState)
    if (!companyID) {
      return PromiseVoid
    }

    const importType = 'OneTimePays'
    dispatch(startAsynchronousTask(companyID))
    return initiateStagedImport(companyID, importType, fileID, oneTimePayType)
      .then((res) => {
        dispatch(savedAsynchronousTask(companyID, res.data))
        return res.data
      })
      .catch((e) => {
        if (isRequestError(e)) {
          dispatch(failedStartingAsynchronousTask(companyID, e))
        }
      })
  }
}
