import AsynchronousTask from '../model/asynchronousTask'
import { ExternalPayRollImportMode } from '../model/dataIntegration'
import { RequestResponse, secureRequest, url } from '../utils/request-utils'

export function fetchAsynchronousTasks(companyID: string): Promise<RequestResponse<AsynchronousTask[]>> {
  return secureRequest('GET', url('/v2/asynchronousTasks', { companyID }))
}

export function fetchAsynchronousTask(taskID: string): Promise<RequestResponse<AsynchronousTask>> {
  return secureRequest('GET', url('/v2/asynchronousTasks/' + taskID))
}

// all calls creating asynchronous tasks
export function postDataIntegrationsPayRoll(
  companyID: string,
  salaryPeriodID: string,
  mode: ExternalPayRollImportMode
): Promise<RequestResponse<AsynchronousTask>> {
  return secureRequest('POST', url('/v2/dataIntegrations/payRoll', { companyID, salaryPeriodID, mode }))
}

export function postDataIntegrationsLeaveRegistrations(
  companyID: string,
  salaryPeriodID: string
): Promise<RequestResponse<AsynchronousTask>> {
  return secureRequest('POST', url('/v2/dataIntegrations/leaveRegistrations', { companyID, salaryPeriodID }))
}

export function postDataIntegrationsLeaveRegistrationsExport(
  companyID: string
): Promise<RequestResponse<AsynchronousTask>> {
  return secureRequest('POST', url('/v2/dataIntegrations/leaveRegistrations/export', { companyID }))
}

export function putDataIntegrationsLeaveRegistrationsExport(
  asynchronousTaskID: string,
  timeRegistrationIDs: string[]
): Promise<RequestResponse<AsynchronousTask>> {
  return secureRequest('PUT', url('/v2/dataIntegrations/leaveRegistrations/export/' + asynchronousTaskID), {
    body: timeRegistrationIDs,
  })
}

export function postDataIntegrationsReimbursements(
  companyID: string,
  salaryPeriodID: string
): Promise<RequestResponse<AsynchronousTask>> {
  return secureRequest('POST', url('/v2/dataIntegrations/reimbursements', { companyID, salaryPeriodID }))
}

export function postDataIntegrationsCarAllowances(
  companyID: string,
  salaryPeriodID: string
): Promise<RequestResponse<AsynchronousTask>> {
  return secureRequest('POST', url('/v2/dataIntegrations/carAllowances', { companyID, salaryPeriodID }))
}
