import { List } from 'immutable'
import React, { ReactElement, useCallback, useContext, useEffect, useState } from 'react'
import { Link } from 'react-router'
import { useEffectOnce } from 'react-use'

import paths from '../../constants/paths'
import logo from '../../images/logo-white-165x49.png'
import Company from '../../model/company'
import CompanyGroup from '../../model/companyGroup'
import Employee from '../../model/employee'
import { ModalOption, ModalType } from '../../model/modal'
import Warning from '../../model/warning'
import { CompanyReducer } from '../../reducers/companies'
import { CompanyUserReducer } from '../../reducers/companyUsers'
import { EmployeeReducer } from '../../reducers/employees'
import { UserReducer } from '../../reducers/user'
import { WarningReducer } from '../../reducers/warnings'
import Language from '../../types/language'
import { hasAllowEnglish, hasQuickLanguageSwitch } from '../../utils/feature-utils'
import { setCurrentLanguage } from '../../utils/language-utils'
import { removeUUIDFromURL } from '../../utils/link-util'
import { getMainMenu, MenuContext, MenuItem } from '../../utils/menu-utils'
import { isDepartmentRestricted } from '../../utils/permissions-utils'
import { RoutePropsLocation } from '../../utils/route-utils'
import { escapeRegExp } from '../../utils/string-utils'
import { t } from '../../utils/translation-utils'
import { isUserSupport } from '../../utils/user-utils'
import Menu from '../antd/menu'
import Icon from '../elements/Icon'
import Input from '../elements/input'
import Popover from '../elements/popover'
import Switch from '../elements/switch'
import UserImage from '../elements/UserImage'
import DumbLink from '../widgets/DumbLink'
import jsBrowserHistory from '../widgets/jsBrowserHistory'
import JumpLink from '../widgets/JumpLink'

import './Header.css'

type Props = {
  location: RoutePropsLocation
  user: UserReducer
  companies: CompanyReducer
  companyUsers: CompanyUserReducer
  companyGroups: List<CompanyGroup>
  employees: EmployeeReducer
  warnings: WarningReducer
  currentLanguage: Language

  setActiveCompany: (id: string) => void
  logout: () => Promise<boolean | void>
  setCurrentLanguage: (language: Language) => void
  addModal: (type: ModalType, options: ModalOption) => void
}

export default function Header(props: Props): ReactElement | null {
  const [inputSearchQuery, setInputSearchQuery] = useState('')
  const [searchQuery, setSearchQuery] = useState('')
  const { menu } = useContext(MenuContext)

  useEffect(() => {
    const searchTimeout = setTimeout(() => setSearchQuery(inputSearchQuery), 100)
    return () => clearTimeout(searchTimeout)
  }, [inputSearchQuery])

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputSearchQuery(e.currentTarget.value.trim())
  }

  const { companies } = props

  const company = companies.company
  const onlyDepartments = isDepartmentRestricted(props.companyUsers.companyUser)

  const numberOfCompanies = companies.companies.size
  const hasMultipleCompanies = numberOfCompanies > 1 && company

  const isMenuItemVisible = (id: string): boolean => {
    return document.getElementById(id)?.parentElement?.offsetTop === -1
  }

  const getDropDownMenu = useCallback((): MenuItem[] => {
    const orgItems = getMainMenu(menu)
    const items: MenuItem[] = []
    orgItems.forEach((item) => {
      if (!isMenuItemVisible(item.id)) {
        items.push(item)
      }
    })
    return items
  }, [menu])

  const [dropDownMenu, setDropDownMenu] = useState<MenuItem[]>([])
  const [init, setInit] = useState(true)

  useEffect(() => {
    if (!init) {
      return
    }
    window.setTimeout(() => {
      window.addEventListener('resize', () => setDropDownMenu(getDropDownMenu()))
      setDropDownMenu(getDropDownMenu())
    }, 2000)
    setInit(false)
  }, [init, getDropDownMenu])

  const handleLogout = (e: React.MouseEvent) => {
    e.preventDefault()
    props
      .logout()
      .then((res) => {
        if (res) {
          jsBrowserHistory.push('/' + paths.LOGIN)
        }
      })
      .catch(() => {
        // TODO: Handle
        return
      })
  }

  const getEmployee = (id: string): Employee | undefined => {
    return props.employees.employees.find((e) => e.id === id)
  }

  const getNotifications = (): Warning[] => {
    return props.warnings.warnings
      .filter((warning) => {
        if (warning.payRollID) {
          return false
        }
        switch (warning.warningType) {
          case 'EmployeeInvalidNationalID':
          case 'EmployeeMissingContract':
          case 'EmployeeEmptyTaxCard':
          case 'EmployeeMissingTaxCard':
          case 'InvalidProductionUnit':
            return warning.subjectID && !!getEmployee(warning.subjectID)
          case 'CompanyNotVerified':
          case 'NETSOnboardingFailed':
          case 'NotRegisteredAsEmployer':
          case 'VoucherBookingFailed':
          case 'VoucherMissingAccount':
          case 'FixCreditCardIntegration':
          case 'DineroOldToken':
            return true
          default:
            return false
        }
      })
      .toArray()
  }

  const getCompanies = (): Company[] => {
    const pattern = searchQuery ? new RegExp(escapeRegExp(searchQuery), 'i') : null
    return props.companies.companies
      .filter((company) => {
        if (pattern) {
          if (!pattern.test(company.name) && !pattern.test(company.nationalID)) {
            return false
          }
        }
        return true
      })
      .toArray()
  }

  const _getActiveMenu = (location: RoutePropsLocation) => {
    let activeMenu = location.pathname
    if (activeMenu.match(new RegExp('^/' + paths.PAY_ROLLS + '/'))) {
      activeMenu = '/' + paths.PAY_ROLLS
    } else if (
      activeMenu.match(new RegExp('^/' + paths.EMPLOYEES + '/')) ||
      activeMenu.match(new RegExp('^/' + paths.EMPLOYEE_TEMPLATES))
    ) {
      activeMenu = '/' + paths.EMPLOYEES
    } else if (activeMenu.match(new RegExp('^/' + paths.FREELANCERS + '/'))) {
      activeMenu = '/' + paths.FREELANCERS
    } else if (
      activeMenu.match(new RegExp('^/' + paths.INTEGRATIONS + '/')) ||
      activeMenu.match(new RegExp('^/' + paths.COMPANIES + '/.+/' + paths.ACCOUNTING + '/add')) ||
      activeMenu.match(new RegExp('^/' + paths.COMPANIES + '/.+/' + paths.TIME_REGISTRATION + '/add'))
    ) {
      activeMenu = '/' + paths.INTEGRATIONS
    } else if (
      activeMenu.match(new RegExp('^/' + paths.COMPANIES + '/')) ||
      activeMenu.match(new RegExp('^/' + paths.COMPANY_GROUPS + '/.+'))
    ) {
      activeMenu = '/' + paths.COMPANIES
    } else if (
      [
        '/' + paths.TIME_REGISTRATION,
        '/' + paths.SALARY_REGISTRATION,
        '/' + paths.LEAVE_REGISTRATION,
        '/' + paths.CAR_ALLOWANCE,
        '/' + paths.ONE_TIME_PAYS,
        '/' + paths.REIMBURSEMENT_VOUCHERS,
        '/' + paths.REIMBURSEMENT_VOUCHERS + '/history',
        '/' + paths.FREELANCERS_OVERVIEW,
        '/' + paths.SWIPE_OVERVIEW,
        '/' + paths.SWIPE_OVERVIEW + '/history',
        '/' + paths.STAGED_IMPORT,
      ].indexOf(activeMenu) !== -1
    ) {
      activeMenu = '/' + paths.APPROVE_TAB
    } else if (
      [
        '/' + paths.DOCUMENTS + '/archive',
        '/' + paths.DOCUMENTS + '/templates',
        '/' + paths.ASSETS,
        '/' + paths.PROJECTS,
      ].indexOf(activeMenu) !== -1
    ) {
      activeMenu = '/' + paths.DOCUMENTS
    }
    return activeMenu
  }

  const renderDropDownMenu = (): ReactElement => {
    return (
      <Menu className="header-dropdown-menu">
        {dropDownMenu.map((item) => {
          return (
            <Menu.Item key={'dropdown' + item.key}>
              {item.type === 'item-label' && (
                <Link to={item.link}>
                  {item.icon}
                  {t(item.labelID)}
                </Link>
              )}
            </Menu.Item>
          )
        })}
      </Menu>
    )
  }

  const buildJumpPath = () => {
    const split = props.location.pathname.split('/')
    if (split.length < 2) {
      return '/' + paths.JUMP + props.location.search
    }
    let result = '/' + paths.JUMP + removeUUIDFromURL(props.location.pathname)
    if (result.split('/').length === 2) {
      // handle a few corner cases
      switch (split[1]) {
        case paths.COMPANIES:
          result = '/' + paths.JUMP + '/' + paths.COMPANIES + '/company'
          break
        case paths.EMPLOYEES:
          result = '/' + paths.JUMP + '/' + paths.EMPLOYEES + '/employment'
          break
        case paths.FREELANCERS:
          result = '/' + paths.JUMP + '/' + paths.FREELANCERS + '/overview'
          break
        case paths.PAY_ROLLS:
          result = '/' + paths.JUMP + '/' + paths.PAY_ROLLS + '/single'
          break
      }
    }
    return result + props.location.search
  }

  const renderUserMenu = (): ReactElement => {
    const company = props.companies.company
    const hasMultipleCompanies =
      props.companies.companies.size > 1 ||
      (!props.companies.company && props.companies.companies.size > 0) ||
      props.companyGroups.size > 0
    const hasSearch = props.companies.companies.size >= 10 || isUserSupport(props.user)
    const onlyDepartments = isDepartmentRestricted(props.companyUsers.companyUser)
    return (
      <Menu className={'user-menu' + (hasMultipleCompanies ? ' user-menu-companies' : '')} selectedKeys={[]}>
        {isUserSupport(props.user) && (
          <Menu.Item className="support-user-field">
            <JumpLink to={buildJumpPath()}>Hjælpehægte til denne side</JumpLink>
          </Menu.Item>
        )}
        {hasMultipleCompanies && (
          <Menu.Item>
            <Link to={'/' + paths.COMPANIES}>
              <Icon type="company" />
              {t('header.all_companies')}
            </Link>
          </Menu.Item>
        )}
        {hasSearch && (
          <Menu.Item>
            <div className="search-field">
              <Icon type="search" color="white" /> <Input onChange={handleSearch} />
            </div>
          </Menu.Item>
        )}
        {hasMultipleCompanies &&
          getCompanies().map((company) => {
            return (
              <Menu.Item key={company.id}>
                <DumbLink
                  onClick={(e) => {
                    e.preventDefault()
                    props.setActiveCompany(company.id)
                    jsBrowserHistory.push('/')
                  }}
                >
                  <UserImage company={company.name} size="xsmall" />
                  {company.name} <span className="cvr">{company.nationalID}</span>
                </DumbLink>
              </Menu.Item>
            )
          })}
        {hasMultipleCompanies && <Menu.Divider />}
        {!onlyDepartments && !hasMultipleCompanies && !!company && (
          <Menu.Item>
            <Link to={'/' + paths.COMPANIES + '/' + company.id}>
              <Icon type="company" />
              {t('header.company')}
            </Link>
          </Menu.Item>
        )}
        <Menu.Item>
          <Link to={'/' + paths.ACCOUNT}>
            <Icon type="user" />
            {t('header.account')}
          </Link>
        </Menu.Item>
        <Menu.Item>
          <DumbLink onClick={handleLogout}>
            <Icon type="key" />
            {t('header.log_out')}
          </DumbLink>
        </Menu.Item>
      </Menu>
    )
  }

  const changeLanguage = (toEnglish: boolean) => {
    // this switch doesn't actually set the language for the user, only the cookie
    // this is by design; this switcher is meant for debugging, and purposefully avoids
    // potentially updating reducers
    if (toEnglish) {
      setCurrentLanguage(Language.ENGLISH)
      props.setCurrentLanguage(Language.ENGLISH)
    } else {
      setCurrentLanguage(Language.DANISH)
      props.setCurrentLanguage(Language.DANISH)
    }
  }

  const notifications = getNotifications().length

  const searchModal = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault()
    props.addModal('global-search', { width: 984 })
  }

  const eventHandler = useCallback(
    (e: KeyboardEvent) => {
      const { code, ctrlKey } = e
      if ('KeyK' === code && ctrlKey) {
        e.preventDefault()
        props.addModal('global-search', { width: 984 })
      }
    },
    [props]
  )

  useEffectOnce(() => {
    // For now, only accept shortcut when the user is a support/admin user
    if (isUserSupport(props.user)) {
      document.addEventListener('keydown', eventHandler)
      return () => document.removeEventListener('keydown', eventHandler)
    }
  })

  return (
    <header className="header">
      <div className="main-menu">
        {hasMultipleCompanies ? (
          <Link to="/" className="header-logo-multiple">
            {company.name}
          </Link>
        ) : (
          <Link to="/" className="header-logo">
            <img src={logo} alt="Salary" />
          </Link>
        )}
        <Menu mode="horizontal" selectedKeys={[_getActiveMenu(props.location)]}>
          {getMainMenu(menu).map((item) => {
            if (item.type === 'item-employee-selector') {
              return null
            }
            if (item.modal) {
              return (
                <Menu.Item key={item.key}>
                  <div onClick={searchModal} className={'gtm-upgrade-to-basic-upgrade'}>
                    {item.icon}
                    {t(item.labelID)}
                  </div>
                </Menu.Item>
              )
            }
            return (
              <Menu.Item key={item.key}>
                <Link to={item.link} id={item.id}>
                  {item.icon}
                  {t(item.labelID)}
                </Link>
              </Menu.Item>
            )
          })}
        </Menu>
      </div>
      <div className="right-menu">
        {dropDownMenu.length > 0 && (
          <Popover
            placement="bottomRight"
            overlayClassName="header-dropdown-menu-popover"
            content={renderDropDownMenu()}
            trigger="click"
          >
            <div className="drop-down-menu" title={t('header.more_menu_items')}>
              <Icon type="arrow-down" color="white" />
            </div>
          </Popover>
        )}
        {hasAllowEnglish() && hasQuickLanguageSwitch() && (
          <div className="ant-switch-wrapper">
            <Switch
              checkedChildren="EN"
              unCheckedChildren="DA"
              checked={props.currentLanguage === Language.ENGLISH}
              onChange={changeLanguage}
              style={{ zIndex: 10000 }}
            />
          </div>
        )}
        {!onlyDepartments && (
          <Link to={'/' + paths.NOTIFICATIONS} className="notification-button">
            <Icon type="bell" />
            {notifications > 0 && <span className="notification-badge">{notifications}</span>}
          </Link>
        )}
        <Popover
          placement="bottomRight"
          overlayClassName="header-user-menu-popover"
          content={renderUserMenu()}
          trigger="click"
        >
          <div className="user-menu-button">
            <UserImage company={company ? company.name : ''} name={props.user.name} size="medium" />
          </div>
        </Popover>
      </div>
    </header>
  )
}
