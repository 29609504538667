import { List } from 'immutable'
import React, { ReactElement } from 'react'

import AvailableDataIntegration from '../../model/availableDataIntegration'
import { FormComponentProps, withValidations } from '../../utils/form-utils'
import { t, tx } from '../../utils/translation-utils'
import Select from '../antd/select'
import Button from '../elements/button'
import Col from '../elements/grid/col'
import Row from '../elements/grid/row'
import Subcard from '../elements/Subcard'
import Subtitle from '../elements/Subtitle'
import FeatureLock from '../widgets/FeatureLock'

type Props = {
  integrationType?: string

  availableDataIntegrations: List<AvailableDataIntegration>
}

type Fields = {
  integrationType?: string
}

export type IntegrationTypeResult = {
  readonly step: 'Integration'
  integrationType: string
}

function IntegrationTypeForm(props: Props & FormComponentProps<Fields, IntegrationTypeResult>): ReactElement | null {
  const integrationFeatures = (integration: AvailableDataIntegration): string => {
    const importFeatures: string[] = []
    if (integration.employeeImport) {
      importFeatures.push(t('company_data_integration.integration_type_form.features.import.employees'))
    }
    if (integration.payRollImport) {
      importFeatures.push(t('company_data_integration.integration_type_form.features.import.pay_roll'))
    }
    if (integration.leaveRegistrationImport) {
      importFeatures.push(t('company_data_integration.integration_type_form.features.import.leave_registrations'))
    }
    if (integration.reimbursementImport) {
      importFeatures.push(t('company_data_integration.integration_type_form.features.import.reimbursements'))
    }
    if (integration.carAllowanceImport) {
      importFeatures.push(t('company_data_integration.integration_type_form.features.import.car_allowances'))
    }
    const exportFeatures: string[] = []
    if (integration.leaveRegistrationExport) {
      exportFeatures.push(t('company_data_integration.integration_type_form.features.export.leave_registrations'))
    }
    if (importFeatures.length === 0 && exportFeatures.length === 0) {
      return ''
    }
    return t(
      importFeatures.length > 0
        ? exportFeatures.length > 0
          ? 'company_data_integration.integration_type_form.features.format.both'
          : 'company_data_integration.integration_type_form.features.format.import'
        : 'company_data_integration.integration_type_form.features.format.export',
      {
        import_features: importFeatures.join(', '),
        export_features: exportFeatures.join(', '),
      }
    )
  }

  const { decorateField } = props

  return (
    <div>
      <Subtitle>{t('company_data_integration.integration_type_form.title')}</Subtitle>
      <p>
        {tx('company_data_integration.integration_type_form.intro', {
          email: <a href="mailto:support@salary.dk">support@salary.dk</a>,
        })}
      </p>
      {props.getFormError()}
      <Subcard className="integration-search">
        {decorateField('integrationType', {
          placeholder: t('company_data_integration.integration_type_form.form.integration_type'),
          validate: (val) =>
            !val ? t('company_data_integration.integration_type_form.form.integration_type.required') : '',
        })(
          <Select dropdownMatchSelectWidth={false}>
            {props.availableDataIntegrations.map((integration) => (
              <Select.Option key={integration.type} value={integration.type}>
                {integration.displayName}
                <div className="integration-features">{integrationFeatures(integration)}</div>
              </Select.Option>
            ))}
          </Select>
        )}
      </Subcard>
      <Row>
        <Col span={24}>
          <FeatureLock
            featureType={'Data Provider'}
            featurePackage={'Premium'}
            description={t('company_data_integration.integration_type_form.submit.lock')}
          >
            <Button
              htmlType="submit"
              size="large"
              type="secondary"
              className="gtm-data-integration-continue-to-connection"
            >
              {t('company_data_integration.integration_type_form.submit')}
            </Button>
          </FeatureLock>
        </Col>
      </Row>
    </div>
  )
}

export default withValidations<Props, Fields, IntegrationTypeResult>({
  mapPropsToFields: (props) => ({
    integrationType: props.integrationType,
  }),
  onSubmit: (values) => ({
    step: 'Integration',
    integrationType: values.integrationType!,
  }),
})(IntegrationTypeForm)
