import React, { ReactElement } from 'react'
import { Link } from 'react-router'

import CustomTable from '../elements/custom-table'
import { GlobalSearchRow } from './GlobalSearch'

type Props = {
  title: string
  rows?: GlobalSearchRow[]
  offset: number
  position: number
}

export default function GlobalSearchCard(props: Props): ReactElement | null {
  if (!props.rows || !props.rows.length) {
    return null
  }
  return (
    <div>
      {props.rows && props.rows.length > 0 && (
        <CustomTable columns={1}>
          <CustomTable.Header>
            <CustomTable.TH>{props.title}</CustomTable.TH>
          </CustomTable.Header>
          <CustomTable.Body>
            {props.rows.map((row, i) => {
              let style = {}
              if (i + props.offset === props.position) {
                style = style = { backgroundColor: 'var(--sally-grey-shaded)' }
              }
              return (
                <CustomTable.Row key={`${row.id}`}>
                  <CustomTable.TD style={style}>
                    <Link to={row.url}>
                      <div>
                        {row.title}
                        <br />
                        {row.description}
                      </div>
                    </Link>
                  </CustomTable.TD>
                </CustomTable.Row>
              )
            })}
          </CustomTable.Body>
        </CustomTable>
      )}
    </div>
  )
}
