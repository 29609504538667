import { List } from 'immutable'
import React, { ReactElement, useEffect } from 'react'

import PayRoll from '../../model/payRoll'
import { formatDate, getDate, isTimeAfter } from '../../utils/date-utils'
import { t } from '../../utils/translation-utils'
import Select from '../antd/select'

type Props = {
  payRolls: List<PayRoll>
  preferOffset: boolean
  periodID: string | undefined

  onSelected: (salaryPeriodID: string) => void
}

export default function PeriodSelector(props: Props): ReactElement | null {
  const activePayrolls = props.payRolls
    .filter((v) => {
      if (v.payRollType !== 'Ordinary') {
        return false
      }
      switch (v.status) {
        case 'Scheduled':
        case 'Upcoming':
        case 'InProgress':
          return true
        default:
          return false
      }
    })
    .sort((a, b) => a.dispositionDate.localeCompare(b.dispositionDate)) // always newest first

  const { periodID, preferOffset } = props
  useEffect(() => {
    if (periodID) {
      return // already have a value, no need to find one
    }
    let payroll: PayRoll | undefined = undefined
    if (activePayrolls.size === 1) {
      payroll = activePayrolls.first()
    } else if (activePayrolls.size > 1) {
      // pick newest
      payroll = activePayrolls.find(
        (v) => (preferOffset && !!v.salaryCycle.offset) || (preferOffset && !v.salaryCycle.offset)
      )
      if (!payroll) {
        // pick newest
        payroll = activePayrolls.first()
      }
    }
    if (!payroll) {
      payroll = props.payRolls.reduce((payRoll: PayRoll | undefined, v) => {
        if (!payRoll || isTimeAfter(getDate(v.salaryPeriod.end), getDate(payRoll.salaryPeriod.end))) {
          return v
        }
        return payRoll
      }, payroll)
    }
    if (payroll) {
      props.onSelected(payroll.salaryPeriod.id)
    }
  }, [periodID, activePayrolls, preferOffset])

  if (activePayrolls.size === 0) {
    return <div>{t('data_integration.period_selector.no_payrolls')}</div>
  }

  return (
    <Select
      dropdownMatchSelectWidth={false}
      style={{ maxWidth: '300px', marginRight: '10px' }}
      onChange={(value: string) => {
        props.onSelected(value)
      }}
      value={periodID}
    >
      {activePayrolls.map((payroll) => {
        return (
          <Select.Option key={payroll.salaryPeriod.id} value={payroll.salaryPeriod.id}>
            {t('date.date_interval', {
              start: formatDate(payroll.salaryPeriod.start),
              end: formatDate(payroll.salaryPeriod.end),
            })}
          </Select.Option>
        )
      })}
    </Select>
  )
}
