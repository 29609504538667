import Fuse from 'fuse.js'
import React, { ReactElement, useCallback, useEffect, useRef, useState } from 'react'

import { fetchIntercomArticles } from '../../api/intercom'
import paths from '../../constants/paths'
import { EmployeeReducer } from '../../reducers/employees'
import { formatError, isRequestError } from '../../utils/error-utils'
import { t } from '../../utils/translation-utils'
import Alert from '../elements/alert'
import { Row } from '../elements/grid'
import Input from '../elements/input/Input'
import GlobalSearchCard from './GlobalSearchCard'

type Props = {
  employees: EmployeeReducer
  employeesLimit: number
  articlesLimit: number
}

type Timer = ReturnType<typeof setTimeout>

export type GlobalSearchRow = {
  id: string
  title: string
  description?: string
  url: string
  target: string
}

export default function GlobalSearch(props: Props): ReactElement | null {
  const [searchTerm, setSearchTerm] = useState('')
  const [employeeResults, setEmployeeResults] = useState<GlobalSearchRow[]>()
  const [articleResults, setArticleResults] = useState<GlobalSearchRow[]>()
  const [error, setError] = useState<Error | null>(null)
  // Determines what searched element is selected.
  // When set to -1 there has been no arrow pressed yet.
  const [selectedPosition, setSelectedPosition] = useState(-1)
  const [fuseEmployees] = useState(() => {
    // Employees fuse options
    const fuseEmployeeOptions = {
      keys: ['name', 'address', 'email', 'nationalID', 'activeEmployment.employeeNumber'],
      threshold: 0.2,
    }
    return new Fuse(props.employees.employees.toArray(), fuseEmployeeOptions)
  })
  const timer = useRef<Timer>()
  const inputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (!inputRef.current) {
      return
    }
    inputRef.current.focus()
  }, [])

  const eventHandler = useCallback(
    (e: KeyboardEvent) => {
      const employeeNumber = (employeeResults && employeeResults.length) || 0
      const articleNumber = (articleResults && articleResults.length) || 0
      const { code } = e
      switch (code) {
        case 'ArrowDown': {
          const previousPosition = selectedPosition
          let m = articleResults?.length || 0
          m += employeeResults?.length || 0
          if (previousPosition + 1 === m) {
            return
          }
          setSelectedPosition(previousPosition + 1)
          return
        }

        case 'ArrowUp': {
          const previousPosition = selectedPosition
          if (previousPosition === 0) {
            return
          }
          setSelectedPosition(previousPosition - 1)
          return
        }

        case 'Enter': {
          if (employeeNumber > 0 && selectedPosition < employeeNumber && employeeResults) {
            const employee = employeeResults[selectedPosition]
            window.location.href = '/' + paths.EMPLOYEES + '/' + employee.id
            return
          }
          if (articleNumber > 0 && selectedPosition >= employeeNumber && articleResults) {
            const article = articleResults[selectedPosition - employeeNumber]
            window.open(article.url, '_blank', 'noopener,noreferrer')
            return
          }
        }
      }
    },
    [selectedPosition, articleResults, employeeResults]
  )

  const targetElement = document

  useEffect(() => {
    targetElement.addEventListener('keydown', eventHandler)
    return () => targetElement.removeEventListener('keydown', eventHandler)
  }, [targetElement, eventHandler])

  const { employeesLimit } = props

  const loadEmployees = useCallback(
    (term: string) => {
      const matches = fuseEmployees.search(term, { limit: employeesLimit })
      const employeeRows: GlobalSearchRow[] = []
      matches.map((e) => {
        employeeRows.push({
          title: e.item.name || e.item.email || '-',
          id: e.item.id,
          url: paths.EMPLOYEES + '/' + e.item.id,
          description: e.item.address + ', ' + e.item.city,
          target: '_self',
        })
      })
      setEmployeeResults(employeeRows)
      if (selectedPosition >= 0) {
        // When adding employee results and a selectedPosition has been changed, we need to update that value accordingly.
        setSelectedPosition((prevState) => matches.length + prevState)
      }
    },
    [employeesLimit, fuseEmployees, selectedPosition]
  )

  const { articlesLimit } = props

  const loadArticles = useCallback(
    (term: string) => {
      fetchIntercomArticles(term, articlesLimit)
        .then((results) => {
          if (results === undefined) {
            setArticleResults([])
            return
          }
          const articleRows: GlobalSearchRow[] = []
          results.data.map((e) => {
            articleRows.push({
              id: e.externalLink,
              title: e.title,
              description: e.description,
              url: e.externalLink,
              target: '_blank',
            })
          })
          setArticleResults(articleRows)
        })
        .catch((e) => {
          if (isRequestError(e)) {
            setError(e)
          }
        })
    },
    [articlesLimit]
  )

  const load = useCallback(
    (term: string) => {
      loadEmployees(term)
      loadArticles(term)
    },
    [loadArticles, loadEmployees]
  )

  useEffect(() => {
    if (!timer.current) {
      return
    }
    clearTimeout(timer.current)
  }, [])

  useEffect(() => {
    setSelectedPosition(-1)
    setArticleResults([])
    setEmployeeResults([])
    if (searchTerm === '') {
      return
    }
    const newTimer = setTimeout(() => {
      load(searchTerm)
    }, 250)
    if (timer.current) {
      clearTimeout(timer.current)
    }
    timer.current = newTimer
  }, [searchTerm, load])

  return (
    <div>
      {error && <Alert message={formatError(error)} type="error" showIcon />}
      <Row>
        <Input
          autoFocus={true}
          onChange={(e) => {
            setSearchTerm(e.target.value)
          }}
          placeholder={t('header.global_search')}
        ></Input>
      </Row>
      <Row>
        <GlobalSearchCard
          title={t('global_search.employees')}
          rows={employeeResults}
          offset={0}
          position={selectedPosition}
        />
        <GlobalSearchCard
          title={t('global_search.articles')}
          rows={articleResults}
          offset={employeeResults?.length || 0}
          position={selectedPosition}
        />
      </Row>
    </div>
  )
}
