import omit from 'omit.js'
import React, { cloneElement, ReactElement } from 'react'
import { useEffectOnce } from 'react-use'

import classNames from '../../antd/_util/classNames'

// NEED_NO_TRANSLATION

function fixControlledValue(value?: string | null) {
  if (typeof value === 'undefined' || value === null) {
    return ''
  }
  return value
}

type Props = React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> & {
  key?: string
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  forwardRef?: React.RefObject<HTMLInputElement>
  readOnly?: boolean
  formMode?: boolean
  prefix?: React.ReactNode
  suffix?: React.ReactNode
  selectAllOnFocus?: boolean
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void
  value?: string
  defaultValue?: string
  onPressEnter?: (e: React.KeyboardEvent<HTMLInputElement>) => void
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void
  setFormValue?: (value: string) => void
  autoFocus?: boolean
}

export default function Input(props: Props): ReactElement | null {
  const input = React.useRef<HTMLInputElement>(props.forwardRef?.current || null)
  const prefixCls = 'ant-input'

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const { onPressEnter, onKeyDown } = props
    if (e.key === 'Enter' && onPressEnter) {
      onPressEnter(e)
    }
    if (onKeyDown) {
      onKeyDown(e)
    }
  }

  const getInputClassName = () => {
    const { disabled } = props
    return classNames(prefixCls, {
      [`${prefixCls}-disabled`]: disabled,
      [`${prefixCls}-right-align`]: !!props.prefix,
    })
  }

  const renderLabeledIcon = (children: ReactElement) => {
    if (!props.prefix && !props.suffix) {
      return children
    }

    const prefix = props.prefix ? <span className={`${prefixCls}-prefix`}>{props.prefix}</span> : null

    const suffix = props.suffix ? <span className={`${prefixCls}-suffix`}>{props.suffix}</span> : null

    return (
      <span className={classNames(props.className, `${prefixCls}-affix-wrapper`)} style={props.style}>
        {prefix}
        {cloneElement(children, { style: null, className: getInputClassName() })}
        {suffix}
      </span>
    )
  }

  useEffectOnce(() => {
    if (props.autoFocus && input.current) {
      input.current.focus()
    }
  })

  const renderInput = () => {
    const { value, className, selectAllOnFocus = true } = props
    let { onFocus } = props

    if (selectAllOnFocus) {
      onFocus = (e) => {
        e.preventDefault()
        if (props.onFocus) {
          props.onFocus(e)
          setTimeout(() => {
            input.current?.select()
          }, 50)
        } else {
          input.current?.select()
        }
      }
    }

    const otherProps = omit(props, [
      'prefix',
      'suffix',
      'forwardRef',
      'selectAllOnFocus',
      'onFocus',
      'onChange',
      'onPressEnter',
      'onKeyDown',
      'formMode',
      'setFormValue',
    ])

    if ('value' in props) {
      otherProps.value = fixControlledValue(value)
      // Input elements must be either controlled or uncontrolled,
      // specify either the value prop, or the defaultValue prop, but not both.
      delete otherProps.defaultValue
    }
    return renderLabeledIcon(
      <input
        {...otherProps}
        onKeyDown={handleKeyDown}
        onChange={props.onChange}
        onFocus={onFocus}
        className={classNames(getInputClassName(), className)}
        ref={input as React.MutableRefObject<HTMLInputElement>}
      />
    )
  }

  return renderInput()
}
