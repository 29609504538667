import React, { ReactElement, ReactNode } from 'react'

import Select from '../components/antd/select'
import Icon from '../components/elements/Icon'
import GlobalSearch from '../components/global-search/GlobalSearch'
import paths from '../constants/paths'
import Employee from '../model/employee'
import { PricingPackageGroup } from '../model/pricingPackage'
import { replaceUUIDInURL } from './link-util'
import { logWarning } from './log-utils'

export type MainMenuTopID =
  | 'main-menu-global-search'
  | 'main-menu-pay-rolls'
  | 'main-menu-employees'
  | 'main-menu-freelancers'
  | 'main-menu-registrations'
  | 'main-menu-registrations-approval'
  | 'main-menu-administration'
  | 'main-menu-administration-documents'
  | 'main-menu-company'
  | 'main-menu-integrations'
  | 'main-menu-integrations-department-only'
  | 'main-menu-dashboard'

export type MainMenuEmployeeID =
  | 'employee-menu-employment'
  | 'employee-menu-profile'
  | 'employee-menu-start-balances'
  | 'employee-menu-leave'
  | 'employee-menu-work-hours-registration'
  | 'employee-menu-time-registration'
  | 'employee-menu-time-box-registration-flex'
  | 'employee-menu-time-box-registration-overtime'
  | 'employee-menu-salary-registration'
  | 'employee-menu-project-registration'
  | 'employee-menu-car-allowance'
  | 'employee-menu-contract-history'
  | 'employee-menu-pay-slips'
  | 'employee-menu-tax-cards'
  | 'employee-menu-settings'

export type MainMenuFreelancerID =
  | 'freelancer-menu-profile'
  | 'freelancer-menu-overview'
  | 'freelancer-menu-payments'
  | 'freelancer-menu-project-registration'
  | 'freelancer-menu-employment'

export type MainMenuRegistrationID =
  | 'registration-menu-approve-tab'
  | 'registration-menu-time-registration'
  | 'registration-menu-salary-registration'
  | 'registration-menu-leave-registration'
  | 'registration-menu-car-allowances'
  | 'registration-menu-work-hours'
  | 'registration-menu-one-time-pays'
  | 'registration-menu-reimbursement-vouchers'
  | 'registration-menu-freelancers'
  | 'registration-menu-swipes'

export type MainMenuAdministrationID =
  | 'administration-menu-documents'
  | 'administration-menu-assets'
  | 'administration-menu-projects'

export type MainMenuCompanyID =
  | 'company-menu-company'
  | 'company-menu-pay'
  | 'company-menu-invoices'
  | 'company-menu-transfers'
  | 'company-menu-tax-cards'
  | 'company-menu-departments'
  | 'company-menu-pricing'
  | 'company-menu-api-keys'
  | 'company-menu-support'
  | 'company-menu-reports'
  | 'company-menu-club'

export type MainMenuCompanyCompanyID =
  | 'company-menu-company-menu-advance-settings'
  | 'company-menu-company-menu-vacation-settings'
  | 'company-menu-company-menu-production-units'
  | 'company-menu-company-menu-users'

export type MainMenuCompanyPayID =
  | 'company-menu-pay-menu-deviations'
  | 'company-menu-pay-menu-user-notifications'
  | 'company-menu-pay-menu-salary-types'
  | 'company-menu-pay-menu-nets-messages'

export type MainMenuCompanyTransferID =
  | 'company-menu-transfers-menu-immediate-pay'
  | 'company-menu-transfers-menu-transfer-nemkonto'
  | 'company-menu-transfers-menu-settings'

export type MainMenuIntegrationID =
  | 'integration-menu-accounting'
  | 'integration-menu-contract-book'
  | 'integration-menu-data-provider'
  | 'integration-menu-codan'
  | 'integration-menu-pension'
  | 'integration-menu-min-refusion'
  | 'integration-menu-rmindr'
  | 'integration-menu-personalezonen'
  | 'integration-menu-advisor'

// IDs not to be used directly, but kept to ensure uniqueness
type MainMenuTechnicalID =
  | 'employee-menu-employee'
  | 'freelancer-menu-freelancer'
  | 'employee-menu-time-box-registration'

export type CombinedMainMenuID =
  | MainMenuTopID
  | MainMenuEmployeeID
  | MainMenuFreelancerID
  | MainMenuRegistrationID
  | MainMenuAdministrationID
  | MainMenuCompanyID
  | MainMenuCompanyCompanyID
  | MainMenuCompanyPayID
  | MainMenuCompanyTransferID
  | MainMenuIntegrationID
  | MainMenuTechnicalID

type InternalMenuItemLabel = {
  type: 'item-label'
  labelID: string | null
  labelIDs?: Partial<Record<CombinedMainMenuID, string>>
  link: string
  alternativeLinks?: Partial<Record<MainMenuTopID, string>>
  modal?: {
    width: number
    height: number
    component: ReactNode
  }
}
type MenuItemEmployeeSelector = {
  type: 'item-employee-selector'
  employeeSelector: (
    employee: Employee[],
    menu: MenuContainer,
    setFields: (fields: { employeeID?: string; freelancerID?: string }) => void
  ) => ReactNode
}

type InternalMenuItem = (InternalMenuItemLabel | MenuItemEmployeeSelector) & {
  id: CombinedMainMenuID
  onID?: CombinedMainMenuID
  key: string
  icon?: ReactElement
  alwaysShowChildren?: boolean
  children?: InternalMenuItem[]
  packageLock?: PricingPackageGroup[]
}

type MenuItemLabel = {
  type: 'item-label'
  labelID: string
  link: string
  modal?: {
    width: number
    height: number
    component: ReactNode
  }
}

export type MenuItem = (MenuItemLabel | MenuItemEmployeeSelector) & {
  id: CombinedMainMenuID
  key: string
  icon?: ReactElement
  alwaysShowChildren?: boolean
  children?: MenuItem[]
  packageLock?: PricingPackageGroup[]
}

export type MenuContainer = {
  visibleFields: CombinedMainMenuID[] // list of IDs to actually show
  companyID?: string // for inserting ID for company pages
  employeeID?: string // for inserting ID for employee pages
  freelancerID?: string // for inserting ID for freelancer pages
}

type setMenuSignature = React.Dispatch<React.SetStateAction<MenuContainer>>

const fullMenu: InternalMenuItem[] = [
  {
    type: 'item-label',
    id: 'main-menu-global-search',
    key: '/' + paths.GLOBAL_SEARCH,
    link: '/' + paths.GLOBAL_SEARCH,
    icon: <Icon type="search" color="white" />,
    labelID: 'header.global_search',
    modal: {
      height: 150,
      width: 150,
      component: GlobalSearch,
    },
  },
  {
    type: 'item-label',
    id: 'main-menu-pay-rolls',
    key: '/' + paths.PAY_ROLLS,
    link: '/' + paths.PAY_ROLLS,
    icon: <Icon type="pay" color="white" />,
    labelID: 'header.pay',
  },
  {
    type: 'item-label',
    id: 'main-menu-employees',
    key: '/' + paths.EMPLOYEES,
    link: '/' + paths.EMPLOYEES,
    icon: <Icon type="team" color="white" />,
    labelID: 'header.employees',
    children: [
      {
        type: 'item-employee-selector',
        id: 'employee-menu-employee',
        onID: 'main-menu-employees',
        key: '/' + paths.EMPLOYEES + '/',
        employeeSelector: (employees, menu, setFields) => {
          return (
            <Select
              dropdownMatchSelectWidth={false}
              showSearch={true}
              filterOption={(inputValue: string, option: ReactElement) => {
                const item = option.props.children
                if (!item) {
                  return false
                }
                return item.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1
              }}
              onChange={(id: string) => setFields({ employeeID: id })}
              value={menu.employeeID}
            >
              {employees
                .filter(
                  (employee) =>
                    employee.affiliationType !== 'Freelancer' &&
                    (employee.employmentStatus === 'New' || employee.employmentStatus === 'Employed')
                )
                .map((employee) => {
                  return (
                    <Select.Option key={employee.id} value={employee.id} title={employee.name ?? employee.email ?? '-'}>
                      {employee.name ?? employee.email ?? '-'}
                    </Select.Option>
                  )
                })}
            </Select>
          )
        },
        alwaysShowChildren: true,
        children: [
          {
            type: 'item-label',
            id: 'employee-menu-employment',
            key: '/' + paths.EMPLOYEES + '/employment',
            link: '/' + paths.EMPLOYEES + '/{employeeID}/employment',
            labelID: 'employee_single.menu.employment',
          },
          {
            type: 'item-label',
            id: 'employee-menu-profile',
            key: '/' + paths.EMPLOYEES + '/profile',
            link: '/' + paths.EMPLOYEES + '/{employeeID}/profile',
            labelID: 'employee_single.menu.profile',
          },
          {
            type: 'item-label',
            id: 'employee-menu-start-balances',
            key: '/' + paths.EMPLOYEES + '/start-balances',
            link: '/' + paths.EMPLOYEES + '/{employeeID}/start-balances',
            labelID: 'employee_single.menu.start_balances',
          },
          {
            type: 'item-label',
            id: 'employee-menu-leave',
            key: '/' + paths.EMPLOYEES + '/leave',
            link: '/' + paths.EMPLOYEES + '/{employeeID}/leave',
            labelID: 'employee_single.menu.leave',
          },
          {
            type: 'item-label',
            id: 'employee-menu-work-hours-registration',
            key: '/' + paths.EMPLOYEES + '/work-hours-registration',
            link: '/' + paths.EMPLOYEES + '/{employeeID}/work-hours-registration',
            labelID: 'employee_single.menu.work_hours_registration',
          },
          {
            type: 'item-label',
            id: 'employee-menu-time-registration',
            key: '/' + paths.EMPLOYEES + '/time-registration',
            link: '/' + paths.EMPLOYEES + '/{employeeID}/time-registration',
            labelID: 'employee_single.menu.time_registration',
          },
          {
            type: 'item-label',
            id: 'employee-menu-time-box-registration',
            key: '/' + paths.EMPLOYEES + '/time-box-registration',
            link: '/' + paths.EMPLOYEES + '/{employeeID}/time-box-registration',
            labelID: null,
            labelIDs: {
              'employee-menu-time-box-registration-flex': 'employee_single.menu.time_box_registration.flex',
              'employee-menu-time-box-registration-overtime': 'employee_single.menu.time_box_registration.overtime',
            },
          },
          {
            type: 'item-label',
            id: 'employee-menu-project-registration',
            key: '/' + paths.EMPLOYEES + '/project-registration',
            link: '/' + paths.EMPLOYEES + '/{employeeID}/project-registration',
            labelID: 'employee_single.menu.project_registration',
          },
          {
            type: 'item-label',
            id: 'employee-menu-salary-registration',
            key: '/' + paths.EMPLOYEES + '/salary-registration',
            link: '/' + paths.EMPLOYEES + '/{employeeID}/salary-registration',
            labelID: 'employee_single.menu.salary_registration',
          },
          {
            type: 'item-label',
            id: 'employee-menu-car-allowance',
            key: '/' + paths.EMPLOYEES + '/car-allowance',
            link: '/' + paths.EMPLOYEES + '/{employeeID}/car-allowance',
            labelID: 'employee_single.menu.car_allowance',
          },
          {
            type: 'item-label',
            id: 'employee-menu-contract-history',
            key: '/' + paths.EMPLOYEES + '/contract-history',
            link: '/' + paths.EMPLOYEES + '/{employeeID}/contract-history',
            labelID: 'employee_single.menu.contract_history',
          },
          {
            type: 'item-label',
            id: 'employee-menu-pay-slips',
            key: '/' + paths.EMPLOYEES + '/pay-slips',
            link: '/' + paths.EMPLOYEES + '/{employeeID}/pay-slips',
            labelID: 'employee_single.menu.pay_slips',
          },
          {
            type: 'item-label',
            id: 'employee-menu-tax-cards',
            key: '/' + paths.EMPLOYEES + '/tax-cards',
            link: '/' + paths.EMPLOYEES + '/{employeeID}/tax-cards',
            labelID: 'employee_single.menu.tax_cards',
          },
          {
            type: 'item-label',
            id: 'employee-menu-settings',
            key: '/' + paths.EMPLOYEES + '/settings',
            link: '/' + paths.EMPLOYEES + '/{employeeID}/settings',
            labelID: 'employee_single.menu.settings',
          },
        ],
      },
    ],
  },
  {
    type: 'item-label',
    id: 'main-menu-freelancers',
    key: '/' + paths.FREELANCERS,
    link: '/' + paths.FREELANCERS,
    icon: <Icon type="user" color="white" />,
    labelID: 'header.freelancers',
    children: [
      {
        type: 'item-employee-selector',
        id: 'freelancer-menu-freelancer',
        onID: 'main-menu-freelancers',
        key: '/' + paths.EMPLOYEES + '/',
        employeeSelector: (employees, menu, setFields) => {
          return (
            <Select
              dropdownMatchSelectWidth={false}
              showSearch={true}
              filterOption={(inputValue: string, option: ReactElement) => {
                const item = option.props.children
                if (!item) {
                  return false
                }
                return item.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1
              }}
              onChange={(id: string) => setFields({ freelancerID: id })}
              value={menu.freelancerID}
            >
              {employees
                .filter(
                  (employee) =>
                    employee.affiliationType === 'Freelancer' &&
                    (employee.employmentStatus === 'New' || employee.employmentStatus === 'Employed')
                )
                .map((employee) => {
                  return (
                    <Select.Option key={employee.id} value={employee.id} title={employee.name ?? employee.email ?? '-'}>
                      {employee.name ?? employee.email ?? '-'}
                    </Select.Option>
                  )
                })}
            </Select>
          )
        },
        alwaysShowChildren: true,
        children: [
          {
            type: 'item-label',
            id: 'freelancer-menu-profile',
            key: '/' + paths.FREELANCERS + '/profile',
            link: '/' + paths.FREELANCERS + '/{freelancerID}/profile',
            labelID: 'freelancer.single.menu.profile',
          },
          {
            type: 'item-label',
            id: 'freelancer-menu-overview',
            key: '/' + paths.FREELANCERS + '/overview',
            link: '/' + paths.FREELANCERS + '/{freelancerID}/overview',
            labelID: 'freelancer.single.menu.overview',
          },
          {
            type: 'item-label',
            id: 'freelancer-menu-payments',
            key: '/' + paths.FREELANCERS + '/payments',
            link: '/' + paths.FREELANCERS + '/{freelancerID}/payments',
            labelID: 'freelancer.single.menu.payments',
          },
          {
            type: 'item-label',
            id: 'freelancer-menu-project-registration',
            key: '/' + paths.FREELANCERS + '/project-registration',
            link: '/' + paths.FREELANCERS + '/{freelancerID}/project-registration',
            labelID: 'freelancer.single.menu.project_registrations',
          },
          {
            type: 'item-label',
            id: 'freelancer-menu-employment',
            key: '/' + paths.FREELANCERS + '/employment',
            link: '/' + paths.FREELANCERS + '/{freelancerID}/employment',
            labelID: 'freelancer.single.menu.employment',
          },
        ],
      },
    ],
  },
  {
    type: 'item-label',
    id: 'main-menu-registrations',
    key: '/' + paths.TIME_REGISTRATION,
    link: '/' + paths.TIME_REGISTRATION,
    alternativeLinks: {
      'main-menu-registrations-approval': '/' + paths.APPROVE_TAB,
    },
    icon: <Icon type="watch" color="white" />,
    labelID: 'header.registrations',
    children: [
      {
        type: 'item-label',
        id: 'registration-menu-approve-tab',
        key: '/' + paths.APPROVE_TAB,
        link: '/' + paths.APPROVE_TAB,
        labelID: 'registrations.layout.awaiting_approval',
      },
      {
        type: 'item-label',
        id: 'registration-menu-time-registration',
        key: '/' + paths.TIME_REGISTRATION,
        link: '/' + paths.TIME_REGISTRATION,
        labelID: 'registrations.layout.time_registrations',
      },
      {
        type: 'item-label',
        id: 'registration-menu-salary-registration',
        key: '/' + paths.SALARY_REGISTRATION,
        link: '/' + paths.SALARY_REGISTRATION,
        labelID: 'registrations.layout.salary_registrations',
      },
      {
        type: 'item-label',
        id: 'registration-menu-leave-registration',
        key: '/' + paths.LEAVE_REGISTRATION,
        link: '/' + paths.LEAVE_REGISTRATION,
        labelID: 'registrations.layout.leave_registrations',
      },
      {
        type: 'item-label',
        id: 'registration-menu-car-allowances',
        key: '/' + paths.CAR_ALLOWANCE,
        link: '/' + paths.CAR_ALLOWANCE,
        labelID: 'registrations.layout.car_allowances',
      },
      {
        type: 'item-label',
        id: 'registration-menu-work-hours',
        key: '/' + paths.WORK_HOURS,
        link: '/' + paths.WORK_HOURS,
        labelID: 'registrations.layout.work_hours',
      },
      {
        type: 'item-label',
        id: 'registration-menu-one-time-pays',
        key: '/' + paths.ONE_TIME_PAYS,
        link: '/' + paths.ONE_TIME_PAYS,
        labelID: 'registrations.layout.one_time_pays',
      },
      {
        type: 'item-label',
        id: 'registration-menu-reimbursement-vouchers',
        key: '/' + paths.REIMBURSEMENT_VOUCHERS,
        link: '/' + paths.REIMBURSEMENT_VOUCHERS,
        labelID: 'registrations.layout.reimbursement_vouchers',
      },
      {
        type: 'item-label',
        id: 'registration-menu-freelancers',
        key: '/' + paths.FREELANCERS_OVERVIEW,
        link: '/' + paths.FREELANCERS_OVERVIEW,
        labelID: 'registrations.layout.freelancers',
      },
      {
        type: 'item-label',
        id: 'registration-menu-swipes',
        key: '/' + paths.SWIPE_OVERVIEW,
        link: '/' + paths.SWIPE_OVERVIEW,
        labelID: 'registrations.layout.swipes',
      },
    ],
  },
  {
    type: 'item-label',
    id: 'main-menu-administration',
    key: '/' + paths.ASSETS,
    link: '/' + paths.ASSETS,
    alternativeLinks: {
      'main-menu-administration-documents': '/' + paths.DOCUMENTS,
    },
    icon: <Icon type="team" color="white" />,
    labelID: 'header.administration',
    children: [
      {
        type: 'item-label',
        id: 'administration-menu-documents',
        key: '/' + paths.DOCUMENTS,
        link: '/' + paths.DOCUMENTS,
        labelID: 'administration_layout.menu.documents',
      },
      {
        type: 'item-label',
        id: 'administration-menu-assets',
        key: '/' + paths.ASSETS,
        link: '/' + paths.ASSETS,
        labelID: 'administration_layout.menu.assets',
      },
      {
        type: 'item-label',
        id: 'administration-menu-projects',
        key: '/' + paths.PROJECTS,
        link: '/' + paths.PROJECTS,
        labelID: 'administration_layout.menu.projects',
      },
    ],
  },
  {
    type: 'item-label',
    id: 'main-menu-company',
    key: '/' + paths.COMPANIES,
    link: '/' + paths.COMPANIES + '/{companyID}',
    icon: <Icon type="gear" color="white" />,
    labelID: 'header.company',
    children: [
      {
        type: 'item-label',
        id: 'company-menu-company',
        key: '/' + paths.COMPANIES,
        link: '/' + paths.COMPANIES + '/{companyID}',
        labelID: 'company.menu.company',
        children: [
          {
            type: 'item-label',
            id: 'company-menu-company-menu-users',
            key: '/' + paths.COMPANIES + '/users',
            link: '/' + paths.COMPANIES + '/{companyID}/users',
            labelID: 'company.edit.header.users',
            icon: <Icon type="team" />,
          },
          {
            type: 'item-label',
            id: 'company-menu-company-menu-production-units',
            key: '/' + paths.COMPANIES + '/production-units',
            link: '/' + paths.COMPANIES + '/{companyID}/production-units',
            labelID: 'company.edit.header.production_units',
          },
          {
            type: 'item-label',
            id: 'company-menu-company-menu-vacation-settings',
            key: '/' + paths.COMPANIES + '/vacation-settings',
            link: '/' + paths.COMPANIES + '/{companyID}/vacation-settings',
            labelID: 'company.edit.header.vacation_settings',
          },
          {
            type: 'item-label',
            id: 'company-menu-company-menu-advance-settings',
            key: '/' + paths.COMPANIES + '/advance-settings',
            link: '/' + paths.COMPANIES + '/{companyID}/advance-settings',
            labelID: 'company.edit.header.advanced_settings',
          },
        ],
      },
      {
        type: 'item-label',
        id: 'company-menu-pay',
        key: '/' + paths.COMPANIES + '/pay',
        link: '/' + paths.COMPANIES + '/{companyID}/pay',
        labelID: 'company.menu.pay',
        children: [
          {
            type: 'item-label',
            id: 'company-menu-pay-menu-user-notifications',
            key: '/' + paths.COMPANIES + '/user-notifications',
            link: '/' + paths.COMPANIES + '/{companyID}/user-notifications',
            labelID: 'company_pay_roll.header.user_notifications',
          },
          {
            type: 'item-label',
            id: 'company-menu-pay-menu-salary-types',
            key: '/' + paths.COMPANIES + '/salary-types',
            link: '/' + paths.COMPANIES + '/{companyID}/salary-types',
            labelID: 'company_pay_roll.header.salary_types',
          },
          {
            type: 'item-label',
            id: 'company-menu-pay-menu-deviations',
            key: '/' + paths.COMPANIES + '/deviations',
            link: '/' + paths.COMPANIES + '/{companyID}/deviations',
            labelID: 'company_pay_roll.header.deviations',
          },
          {
            type: 'item-label',
            id: 'company-menu-pay-menu-nets-messages',
            key: '/' + paths.COMPANIES + '/nets-messages',
            link: '/' + paths.COMPANIES + '/{companyID}/nets-messages',
            labelID: 'company_pay_roll.header.nets_messages',
          },
        ],
      },
      {
        type: 'item-label',
        id: 'company-menu-invoices',
        key: '/' + paths.COMPANIES + '/invoices',
        link: '/' + paths.COMPANIES + '/{companyID}/invoices',
        labelID: 'company.menu.invoices',
      },
      {
        type: 'item-label',
        id: 'company-menu-transfers',
        key: '/' + paths.COMPANIES + '/transfers',
        link: '/' + paths.COMPANIES + '/{companyID}/transfers',
        labelID: 'company.menu.transfers',
        children: [
          {
            type: 'item-label',
            id: 'company-menu-transfers-menu-settings',
            key: '/' + paths.COMPANIES + '/transfer-settings',
            link: '/' + paths.COMPANIES + '/{companyID}/transfer-settings',
            labelID: 'transfers_tab.header.transfer_settings',
          },
          {
            type: 'item-label',
            id: 'company-menu-transfers-menu-transfer-nemkonto',
            key: '/' + paths.COMPANIES + '/transfer-nemkonto',
            link: '/' + paths.COMPANIES + '/{companyID}/transfer-nemkonto',
            labelID: 'transfers_tab.header.nemkonto',
          },
          {
            type: 'item-label',
            id: 'company-menu-transfers-menu-immediate-pay',
            key: '/' + paths.COMPANIES + '/transfer-immediate-pay',
            link: '/' + paths.COMPANIES + '/{companyID}/transfer-immediate-pay',
            labelID: 'transfers_tab.header.immediate_pay',
          },
        ],
      },
      {
        type: 'item-label',
        id: 'company-menu-departments',
        key: '/' + paths.COMPANIES + '/departments',
        link: '/' + paths.COMPANIES + '/{companyID}/departments',
        labelID: 'company.menu.departments',
      },
      {
        type: 'item-label',
        id: 'company-menu-pricing',
        key: '/' + paths.COMPANIES + '/pricing',
        link: '/' + paths.COMPANIES + '/{companyID}/pricing',
        labelID: 'company.menu.pricing',
      },
      {
        type: 'item-label',
        id: 'company-menu-api-keys',
        key: '/' + paths.COMPANIES + '/api-keys',
        link: '/' + paths.COMPANIES + '/{companyID}/api-keys',
        labelID: 'company.menu.api_keys',
      },
      {
        type: 'item-label',
        id: 'company-menu-support',
        key: '/' + paths.COMPANIES + '/support',
        link: '/' + paths.COMPANIES + '/{companyID}/support',
        labelID: 'company.menu.support',
      },
      {
        type: 'item-label',
        id: 'company-menu-reports',
        key: '/' + paths.COMPANIES + '/reports',
        link: '/' + paths.COMPANIES + '/{companyID}/reports',
        labelID: 'company.menu.reports',
      },
      {
        type: 'item-label',
        id: 'company-menu-club',
        key: 'https://salary.dk/fordelsklub',
        link: 'https://salary.dk/fordelsklub',
        labelID: 'company.menu.club',
      },
    ],
  },
  {
    type: 'item-label',
    id: 'main-menu-integrations',
    key: '/' + paths.INTEGRATIONS,
    link: '/' + paths.INTEGRATIONS + '/' + paths.ACCOUNTING,
    alternativeLinks: {
      'main-menu-integrations-department-only': '/' + paths.INTEGRATIONS + '/' + paths.CONTRACT_BOOK,
    },
    icon: <Icon type="speechbubbles" color="white" />,
    labelID: 'header.integrations',
    children: [
      {
        type: 'item-label',
        id: 'integration-menu-accounting',
        key: '/' + paths.INTEGRATIONS + '/' + paths.ACCOUNTING,
        link: '/' + paths.INTEGRATIONS + '/' + paths.ACCOUNTING,
        labelID: 'integration_layout.menu.accounting',
      },
      {
        type: 'item-label',
        id: 'integration-menu-contract-book',
        key: '/' + paths.INTEGRATIONS + '/' + paths.CONTRACT_BOOK,
        link: '/' + paths.INTEGRATIONS + '/' + paths.CONTRACT_BOOK,
        labelID: 'integration_layout.menu.contract_book',
      },
      {
        type: 'item-label',
        id: 'integration-menu-data-provider',
        key: '/' + paths.INTEGRATIONS + '/' + paths.DATA_PROVIDER,
        link: '/' + paths.INTEGRATIONS + '/' + paths.DATA_PROVIDER,
        labelID: 'integration_layout.menu.data_provider',
      },
      {
        type: 'item-label',
        id: 'integration-menu-codan',
        key: '/' + paths.INTEGRATIONS + '/codan',
        link: '/' + paths.INTEGRATIONS + '/codan',
        labelID: 'integration_layout.menu.codan',
      },
      {
        type: 'item-label',
        id: 'integration-menu-pension',
        key: '/' + paths.INTEGRATIONS + '/pension',
        link: '/' + paths.INTEGRATIONS + '/pension',
        labelID: 'integration_layout.menu.pension',
      },
      {
        type: 'item-label',
        id: 'integration-menu-min-refusion',
        key: '/' + paths.INTEGRATIONS + '/minrefusion',
        link: '/' + paths.INTEGRATIONS + '/minrefusion',
        labelID: 'integration_layout.menu.min_refusion',
      },
      {
        type: 'item-label',
        id: 'integration-menu-rmindr',
        key: '/' + paths.INTEGRATIONS + '/rmindr',
        link: '/' + paths.INTEGRATIONS + '/rmindr',
        labelID: 'integration_layout.menu.rmindr',
      },
      {
        type: 'item-label',
        id: 'integration-menu-personalezonen',
        key: '/' + paths.INTEGRATIONS + '/personalezonen',
        link: '/' + paths.INTEGRATIONS + '/personalezonen',
        labelID: 'integration_layout.menu.personalezonen',
        packageLock: ['Premium'],
      },
      {
        type: 'item-label',
        id: 'integration-menu-advisor',
        key: '/' + paths.INTEGRATIONS + '/advisor',
        link: '/' + paths.INTEGRATIONS + '/advisor',
        labelID: 'integration_layout.menu.advisor',
      },
    ],
  },
  {
    type: 'item-label',
    id: 'main-menu-dashboard',
    key: '/' + paths.DASHBOARD,
    link: '/' + paths.DASHBOARD,
    icon: <Icon type="dashboard" />,
    labelID: 'header.dashboard',
  },
]

export const defaultMenuContainer: MenuContainer = {
  visibleFields: [], // none per default, pages will make sure to update this
}

// this will be set by App.tsx
export const MenuContext = React.createContext<{ menu: MenuContainer; setMenu: setMenuSignature }>({
  menu: defaultMenuContainer,
  setMenu: (_): MenuContainer => {
    return defaultMenuContainer
  },
})

// SET FUNCTIONS ---------------------------

export function setMainMenuTopMenu(setMenu: setMenuSignature, companyID: string | undefined, fields: MainMenuTopID[]) {
  setMenu((prev) => {
    // first we remove the main-menu items
    const visibleFields = prev.visibleFields.filter((id) => !id.match(/^main-menu.*/))
    // then we added the ones provided
    visibleFields.push(...fields)
    return { ...prev, visibleFields, companyID }
  })
}

/**
 * Set the employee fields and ID for the main menu
 * @param setMenu
 * @param employeeID
 * @param fields
 */
export function setMainMenuEmployeeMenu(setMenu: setMenuSignature, employeeID: string, fields: MainMenuEmployeeID[]) {
  setMenu((prev) => {
    // first we remove all the employee-menu specific visible fields
    const visibleFields = prev.visibleFields.filter((id) => !id.match(/^employee-menu.+/))
    // then we add the ones just provided
    visibleFields.push(...fields)
    return { ...prev, visibleFields, employeeID }
  })
}

export function setMainMenuEmployee(setMenu: setMenuSignature, employeeID: string) {
  setMenu((prev) => ({ ...prev, employeeID }))
}

export function setMainMenuFreelancerMenu(
  setMenu: setMenuSignature,
  freelancerID: string,
  fields: MainMenuFreelancerID[]
) {
  setMenu((prev) => {
    // first we remove all the freelancer-menu specific visible fields
    const visibleFields = prev.visibleFields.filter((id) => !id.match(/^freelancer-menu.+/))
    // then we add the ones just provided
    visibleFields.push(...fields)
    return { ...prev, visibleFields, freelancerID }
  })
}

export function setMainMenuFreelancer(setMenu: setMenuSignature, freelancerID: string) {
  setMenu((prev) => ({ ...prev, freelancerID }))
}

export function setMainMenuRegistrationMenu(setMenu: setMenuSignature, fields: MainMenuRegistrationID[]) {
  setMenu((prev) => {
    // first we remove all the registration-menu specific visible fields
    const visibleFields = prev.visibleFields.filter((id) => !id.match(/^registration-menu.+/))
    // then we add the ones just provided
    visibleFields.push(...fields)
    return { ...prev, visibleFields }
  })
}

export function setMainMenuAdministrationMenu(setMenu: setMenuSignature, fields: MainMenuAdministrationID[]) {
  setMenu((prev) => {
    // first we remove all the administration-menu specific visible fields
    const visibleFields = prev.visibleFields.filter((id) => !id.match(/^administration-menu.+/))
    // then we add the ones just provided
    visibleFields.push(...fields)
    return { ...prev, visibleFields }
  })
}

export function setMainMenuCompanyMenu(
  setMenu: setMenuSignature,
  fields: (MainMenuCompanyID | MainMenuCompanyCompanyID | MainMenuCompanyPayID | MainMenuCompanyTransferID)[]
) {
  setMenu((prev) => {
    // first we remove all the company-menu specific visible fields
    const visibleFields = prev.visibleFields.filter((id) => !id.match(/^company-menu.+/))
    // then we add the ones just provided
    visibleFields.push(...fields)
    return { ...prev, visibleFields }
  })
}

export function setMainMenuIntegrationMenu(setMenu: setMenuSignature, fields: MainMenuIntegrationID[]) {
  setMenu((prev) => {
    // first we remove all the integration-menu specific visible fields
    const visibleFields = prev.visibleFields.filter((id) => !id.match(/^integration-menu.+/))
    // then we add the ones just provided
    visibleFields.push(...fields)
    return { ...prev, visibleFields }
  })
}

// GET FUNCTIONS ---------------------------

// first step in preparing the menu for display, this filters the items
function filterMenuForDisplay(menu: InternalMenuItem[], visibleFields: CombinedMainMenuID[]): InternalMenuItem[] {
  return menu
    .map((item) => {
      // handle the odd cases, where there are alternative IDs
      // we rewrite the item, so it matches the intended item,
      // i.e. label and link gets rewritten, as so does the ID field
      let children = item.children
      if (children) {
        children = filterMenuForDisplay(children, visibleFields)
      }
      if (item.type === 'item-employee-selector') {
        return { ...item, children }
      }
      let link = item.link
      // first handle alternative links (which modifies the link)
      if (item.alternativeLinks) {
        for (const [key, value] of Object.entries(item.alternativeLinks)) {
          if (visibleFields.includes(key as CombinedMainMenuID)) {
            link = value
            break
          }
        }
      }
      let labelID = item.labelID
      // then alternative labels (which modifies the labelID)
      if (item.labelIDs) {
        for (const [key, value] of Object.entries(item.labelIDs)) {
          if (visibleFields.includes(key as CombinedMainMenuID)) {
            labelID = value
            break
          }
        }
      }
      // we return the objects like this to ensure they are cloned
      return { ...item, link, labelID, children }
    })
    .filter(
      (item) =>
        visibleFields.includes(item.id) ||
        (item.onID && visibleFields.includes(item.onID)) ||
        (item.type === 'item-label' &&
          ((item.alternativeLinks &&
            Object.keys(item.alternativeLinks).some((id) => visibleFields.includes(id as CombinedMainMenuID))) ||
            (item.labelIDs &&
              Object.keys(item.labelIDs).some((id) => visibleFields.includes(id as CombinedMainMenuID)))))
    )
}

// second step in preparing the items for display, such as fixing the links
function fixItemsForDisplay(
  items: InternalMenuItem[],
  companyID?: string,
  employeeID?: string,
  freelancerID?: string
): MenuItem[] {
  return (
    items
      .map((item) => {
        let children = item.children
        if (children) {
          children = fixItemsForDisplay(children, companyID, employeeID, freelancerID)
        }
        if (item.type === 'item-employee-selector') {
          return { ...item, children }
        }
        let link = item.link
        if (link.match(/.*\{companyID}.*/)) {
          if (!companyID) {
            logWarning('Trying to show menu item with companyID without companyID')
            return null
          }
          link = link.replace('{companyID}', companyID)
        }
        if (link.match(/.*\{employeeID}.*/)) {
          if (!employeeID) {
            logWarning('Trying to show menu item with employeeID without employeeID')
            return null
          }
          link = link.replace('{employeeID}', employeeID)
        }
        if (link.match(/.*\{freelancerID}.*/)) {
          if (!freelancerID) {
            logWarning('Trying to show menu item with freelancerID without freelancerID')
            return null
          }
          link = link.replace('{freelancerID}', freelancerID)
        }
        // we return the objects like this to ensure they are cloned
        return { ...item, link, children }
      })
      // since we filter on non-null items, we can do as
      .filter((item) => item !== null) as MenuItem[]
  )
}

export function getMainMenu(menu: MenuContainer): MenuItem[] {
  return fixItemsForDisplay(
    filterMenuForDisplay(fullMenu, menu.visibleFields),
    menu.companyID,
    menu.employeeID,
    menu.freelancerID
  )
}

function getSubMenu(menu: MenuContainer, depthIDs: CombinedMainMenuID[]): MenuItem[] {
  const getItems = (items: InternalMenuItem[], index: number): InternalMenuItem[] => {
    if (depthIDs.length <= index) {
      return items
    }
    const subItems = items.find((item) => item.id === depthIDs[index])?.children
    if (!subItems) {
      return []
    }
    if (depthIDs.length >= index + 1) {
      return getItems(subItems, index + 1)
    }
    return subItems
  }
  const items = getItems(fullMenu, 0)
  if (items.length === 0) {
    return []
  }
  // only return the items found in the visible fields
  return fixItemsForDisplay(
    filterMenuForDisplay(items, menu.visibleFields),
    menu.companyID,
    menu.employeeID,
    menu.freelancerID
  )
}

/**
 * Return only the employee portion (for use with menu on top)
 * @param menu
 */
export function getMainMenuEmployeeMenu(menu: MenuContainer): MenuItem[] {
  return getSubMenu(menu, ['main-menu-employees', 'employee-menu-employee'])
}

/**
 * Return only the freelancer portion (for use with menu on top)
 * @param menu
 */
export function getMainMenuFreelancerMenu(menu: MenuContainer): MenuItem[] {
  return getSubMenu(menu, ['main-menu-freelancers', 'freelancer-menu-freelancer'])
}

export function getMainMenuRegistrationMenu(menu: MenuContainer): MenuItem[] {
  return getSubMenu(menu, ['main-menu-registrations'])
}

export function getMainMenuAdministrationMenu(menu: MenuContainer): MenuItem[] {
  return getSubMenu(menu, ['main-menu-administration'])
}

export function getMainMenuCompanyMenu(menu: MenuContainer): MenuItem[] {
  return getSubMenu(menu, ['main-menu-company'])
}

export function getMainMenuCompanyMenuCompany(menu: MenuContainer): MenuItem[] {
  return getSubMenu(menu, ['main-menu-company', 'company-menu-company'])
}

export function getMainMenuCompanyMenuPay(menu: MenuContainer): MenuItem[] {
  return getSubMenu(menu, ['main-menu-company', 'company-menu-pay'])
}

export function getMainMenuCompanyMenuTransfer(menu: MenuContainer): MenuItem[] {
  return getSubMenu(menu, ['main-menu-company', 'company-menu-transfers'])
}

export function getMainMenuIntegrationMenu(menu: MenuContainer): MenuItem[] {
  return getSubMenu(menu, ['main-menu-integrations'])
}

// OTHER FUNCTIONS ------------------

export function getAllParentsForCurrentPath(pathname: string): MenuItem[] {
  const linkRegex = new RegExp(
    '^' + replaceUUIDInURL(pathname, `(\\{employeeID\\}|\\{freelancerID\\}|\\{companyID\\})`) + '$'
  )
  const matchLink = (link: string) => {
    return !!link.match(linkRegex)
  }
  const walk = (items: InternalMenuItem[]): InternalMenuItem[] =>
    items.reduce((items: InternalMenuItem[], item) => {
      if (item.children) {
        const moreItems = walk(item.children)
        if (moreItems.length > 0) {
          items.push(...moreItems, item)
        }
      }
      if (item.type === 'item-label' && matchLink(item.link)) {
        return [...items, item]
      }
      return items
    }, [])

  return walk(fullMenu) as MenuItem[]
}

export function getTopParentForCurrentPath(pathname: string): MenuItem | undefined {
  const list = getAllParentsForCurrentPath(pathname)
  if (list.length === 0) {
    return undefined
  }
  return list[list.length - 1]
}
