import React from 'react'

import { StoredError } from '../model/error'
import { formatDate } from './date-utils'
import { logError, logWarning } from './log-utils'
import { ChannelMFAError, RequestError } from './request-utils'
import { t } from './translation-utils'

export function isRequestError(e: unknown) {
  if (e instanceof RequestError) {
    return true
  }
  throw e
}

export function isMFAChannelError(e: unknown) {
  return e instanceof ChannelMFAError
}

function formatInvalidEmployeeDataDetail(detail: string) {
  switch (detail) {
    case 'The bank registration number must be at most 4 digits':
      return t('error.invalid_employee_data.registration_number_at_most_4_digits')
    case 'The bank account number must be at most 10 digits':
      return t('error.invalid_employee_data.account_number_at_most_10_digits')
    case 'Missing country':
      return t('error.invalid_employee_data.missing_country')
    case 'Only freelancers can have a CVR':
      return t('error.invalid_employee_data.only_freelancers_can_have_cvr')
    case 'DK Foreign Residents can not have address in DK':
      return t('error.invalid_employee_data.dk_foreign_residents_no_address_in_dk')
    case 'DK Foreign Residents must have gender':
      return t('error.invalid_employee_data.dk_foreign_residents_must_have_gender')
    case 'DK Foreign Residents must have birth date':
      return t('error.invalid_employee_data.dk_foreign_residents_must_have_birth_date')
    case 'DK without CPR can not have address in DK':
      return t('error.invalid_employee_data.dk_without_cpr_no_address_in_dk')
    case 'DK without CPR must have gender':
      return t('error.invalid_employee_data.dk_without_cpr_must_have_gender')
    case 'DK without CPR must have birth date':
      return t('error.invalid_employee_data.dk_without_cpr_must_have_birth_date')
    case 'DK without CPR can not have national ID':
      return t('error.invalid_employee_data.dk_without_cpr_no_national_id')
    case 'Bad formatted employee email':
      return t('error.invalid_employee_data.bad_formatted_email')
    case 'Employee name is missing':
      return t('error.invalid_employee_data.name_is_missing')
    case 'Employee address is missing':
      return t('error.invalid_employee_data.address_is_missing')
    case 'Employee city is missing':
      return t('error.invalid_employee_data.city_is_missing')
    case 'Employee postal code is missing':
      return t('error.invalid_employee_data.postal_code_is_missing')
    case 'National ID must be exactly 10 digits':
      return t('error.invalid_employee_data.national_id_must_be_10_digits')
    case 'National ID must be exactly 8 digits':
      return t('error.invalid_employee_data.national_id_must_be_8_digits')
    case 'NemKonto transfers are disabled for company':
      return t('error.invalid_employee_data.nemkonto_transfers_disabled')
    case 'NemKonto transfers needs CPR-number':
      return t('error.invalid_employee_data.nemkonto_need_cpr')
    case 'NemKonto CVR transfers needs CVR-number':
      return t('error.invalid_employee_data.nemkonto_need_cvr')
    default:
      return detail
  }
}

/**
 * Only format basic string error messages, and returns string
 *
 * If it does not know the error, it returns itself
 * @param errorMessage
 */
function formatErrorMessage(errorMessage: string): string {
  switch (errorMessage) {
    case 'Access Token Not Privileged':
      return t('error.message.access_token_not_privileged')
    case 'Account Mapping Not Permitted for this Accounting Integration':
      return t('error.message.account_mapping_not_permitted_for_this_accounting_integration')
    case 'Accounting API key invalid':
      return t('error.message.accounting_api_key_invalid')
    case 'Accounting API auth invalid':
      return t('error.message.accounting_api_auth_invalid')
    case 'No Endpoint For Inventio Accounting Integration':
      return t('error.message.no_endpoint_for_inventio_accounting_integration')
    case 'SMARTapi Not Active For Inventio Accounting Integration':
      return t('error.message.smart_api_not_active_for_inventio_accounting_integration')
    case 'Accounting Integration Setup Not Found':
      return t('error.message.accounting_integration_setup_not_found')
    case 'Car Allowance Immutable':
      return t('error.message.car_allowance_immutable')
    case 'Coarse Car Allowance Already Exists':
      return t('error.message.coarse_car_allowance_already_exists')
    case 'Coarse Car Allowance Immutable':
      return t('error.message.coarse_car_allowance_immutable')
    case 'Coarse Time Registration Already Exists':
      return t('error.message.coarse_time_registration_already_exists')
    case 'Coarse Time Registration Immutable':
      return t('error.message.coarse_time_registration_immutable')
    case 'Coarse Time Registration Period Is Approved':
      return t('error.message.coarse_time_registration_period_is_approved')
    case 'Company Accounting Integration Not Found':
      return t('error.message.company_accounting_integration_not_found')
    case 'Company Feature Missing':
      return t('error.message.company_feature_missing')
    case 'Company Is Frozen':
      return t('error.message.company_is_frozen')
    case 'Company Logo Format Invalid':
      return t('error.message.company_logo_format_invalid')
    case 'Company Logo Size Invalid':
      return t('error.message.company_logo_size_invalid')
    case 'Company Not Found':
      return t('error.message.company_not_found')
    case 'Concurrency Issue Detected':
      return t('error.message.concurrency_issue_detected')
    case 'ContractBook Invalid API Key':
      return t('error.message.contractbook_invalid_api_key')
    case 'Department Not Found':
      return t('error.message.department_not_found')
    case 'Email Token Not Found':
      return t('error.message.email_token_not_found')
    case 'Employee Cap Exceeded':
      return t('error.message.employee_cap_exceeded')
    case 'Employee Contract Immutable':
      return t('error.message.employee_contract_immutable')
    case 'Employee Contract Invalid':
      return t('error.message.employee_contract_invalid')
    case 'Employee Contract Not Found':
      return t('error.message.employee_contract_not_found')
    case 'Employee Employed In Company':
      return t('error.message.employee_employed_in_company')
    case 'Employee Image Format Invalid':
      return t('error.message.employee_image_format_invalid')
    case 'Employee Immutable':
      return t('error.message.employee_immutable')
    case 'Employee Time Registration Already Exists':
      return t('error.message.employee_time_registration_already_exists')
    case 'Employee Time Registration Excess Hours Pr Day':
      return t('error.message.employee_time_registration_excess_hour_per_day')
    case 'Employee Time Registration Immutable':
      return t('error.message.employee_time_registration_immutable')
    case 'Employee Time Registration Invalid Method':
      return t('error.message.employee_time_registration_invalid_method')
    case 'Employment Already Terminated':
      return t('error.message.employment_already_terminated')
    case 'Employment Not Found':
      return t('error.message.employment_not_found')
    case 'Employment Invalid':
      return t('error.message.employment_invalid')
    case 'Employment Number Is Not Unique':
      return t('error.message.employment_number_is_not_unique')
    case 'Employment Number Invalid':
      return t('error.message.employment_number_invalid')
    case 'Import Could Not Log In':
      return t('error.message.import_could_not_log_in')
    case 'Import Failed':
      return t('error.message.import_failed')
    case 'User Data Invalid':
      return t('error.message.user_data_invalid')
    case 'Invalid National ID':
      return t('error.message.invalid_national_id')
    case 'Leave Type Not Found':
      return t('error.message.leave_type_not_found')
    case 'MFA Wrong Response':
      return t('error.message.mfa_wrong_response')
    case 'Pension Definition Invalid':
      return t('error.message.pension_definition_invalid')
    case 'Pension Definition Invalid Destination':
      return t('error.message.pension_definition_invalid_destination')
    case 'Pension Definition Invalid Union Agreement Number':
      return t('error.message.pension_definition_invalid_union_agreement_number')
    case 'Phone Number Invalid':
      return t('error.message.phone_number_invalid')
    case 'Pricing Package Not Found':
      return t('error.message.pricing_package_not_found')
    case 'Pro Feature Missing For Dinero Accounting Integration':
      return t('error.message.pro_feature_missing_for_dinero_accounting_integration')
    case 'Production Unit Already Exists':
      return t('error.message.production_unit_already_exists')
    case 'Reimbursement Voucher Already Approved':
      return t('error.message.reimbursement_voucher_already_approved')
    case 'Salary Definition Invalid':
      return t('error.message.salary_definition_invalid')
    case 'Salary Definition Not Found':
      return t('error.message.salary_definition_not_found')
    case 'Salary Period Invalid':
      return t('error.message.salary_period_invalid')
    case 'Salary Type Is In Use':
      return t('error.message.salary_type_is_in_use')
    case 'Shipment Transfer Row Is Immutable':
      return t('error.message.shipment_transfer_row_is_immutable')
    case 'Unauthorized':
      return t('error.message.unauthorized')
    case 'User Not Found':
      return t('error.message.user_not_found')
    case 'Workflow Cannot Be Cancelled':
      return t('error.message.workflow_cannot_be_cancelled')
    case 'Workflow Not Found':
      return t('error.message.workflow_not_found')
    case 'Workflow Task Not In Approval State':
      return t('error.message.workflow_task_not_in_approval_state')
    case 'Company Has Future Contracts':
      return t('error.message.company_has_future_contracts')
    case 'Coarse Time Registration More Than One Contract in Period':
      return t('error.message.coarse_time_registration_more_than_one_contract_in_period')
    case 'Support may not perform this action':
      return t('error.message.support_may_not_perform_this_action')
    case 'Car Allowance Invalid Method':
      return t('error.message.car_allowance_invalid_method')
    case 'Employee Batch Data Invalid':
      return t('error.message.employee_batch_data_invalid')
    case 'Email Not Found':
      return t('error.message.email_not_found')
    case 'Transaction Immutable':
      return t('error.message.transaction_immutable')
    case 'ContractBook API Error':
      return t('error.message.contractbook_api_error')
    case 'ContractBook Internal API Error':
      return t('error.message.contractbook_internal_api_error')
    case 'No Environment Found for Business Central Accounting Integration':
      return t('error.message.no_environment_found_for_business_central_accounting_integration')
    case 'Tax card Request Not Found':
      return t('error.message.tax_card_request_not_found')
    case 'Company Payment Transfer Setting Not Allowed':
      return t('error.message.company_payment_transfer_setting_not_allowed')
    case 'Time Registration API Invalid Key':
      return t('error.message.time_registration_api_invalid_key')
    case 'Invalid Family Fund For Company':
      return t('error.message.invalid_family_fund_for_company')
    case 'Employment Terminated Wrong Leave Type':
      return t('error.message.employment_terminated_wrong_leave_type')
    case 'Accounting Integration Request Failed Try Again':
      return t('error.message.accounting_integration_request_failed_try_again')
    case 'Company Feature Not Enabled':
      return t('error.message.company_feature_not_enabled')
    case 'One Time Pay Immutable':
      return t('error.message.one_time_pay_immutable')
    case 'Reimbursement Voucher Has No Expense Category':
      return t('error.message.reimbursement_voucher_has_no_expense_category')
    case 'Phone Number Is Used For MFA':
      return t('error.message.phone_number_is_used_for_mfa')
    case 'Employee Leave Invalid':
      return t('error.message.employee_leave_invalid')
    case 'Invalid Transfer Destination':
      return t('error.message.invalid_transfer_destination')
    case 'Reimbursement Voucher Unsupported File Format':
      return t('error.message.reimbursement_voucher_unsupported_file_format')
    case 'Incorrect Format For Staged Document':
      return t('error.message.incorrect_format_for_staged_document')
    case 'Employee Is Director':
      return t('error.message.employee_is_director')
    case 'Grandhood API User Already Exists':
      return t('error.message.grandhood_api_user_already_exists')
    case 'Reimbursement Voucher Has No Disburse Amount':
      return t('error.message.reimbursement_voucher_has_no_disburse_amount')
    case 'Data Provider API Invalid Key':
      return t('error.message.data_provider_api_invalid_key')
    case 'Document Unsupported File Format':
      return t('error.message.document_unsupported_file_format')
    case 'Pricing Package Campaign Code Invalid':
      return t('error.message.pricing_package_campaign_code_invalid')
    case 'Employee Not Found':
      return t('error.message.employee_not_found')
    case 'Asynchronous Task Staged Data Already Used':
      return t('error.message.asynchronous_task_staged_data_already_used')
    case 'Employee Data Too Recent':
      return t('error.message.employee_data_too_recent')
    case 'Employee Contract Salary Period Overlap':
      return t('error.message.employee_contract_salary_period_overlap')
    case 'Password must be at least 8 characters':
      return t('error.message.password_must_be_at_least_8_characters')
    case 'Password must not be listed in public data breach':
      return t('error.message.password_must_not_be_listed_in_public_data_breach')
    case 'Accounting Integration Re Auth Setup Mismatch':
      return t('error.message.accounting_integration_re_auth_setup_mismatch')
    case 'Payroll Approve Code Required':
      return t('error.message.payroll_approve_code_required')
    case 'Payroll Approve Code Incorrect':
      return t('error.message.payroll_approve_code_incorrect')
    case 'unauthenticated for invalid credentials':
      return t('error.message.unauthenticated_for_invalid_credentials')
    case 'No Accounting Year in Dinero Accounting Integration':
      return t('error.message.no_accounting_year_in_dinero_accounting_integration')
    case 'Accounting Integration Missing Account Mapping':
      return t('error.message.accounting_integration_missing_account_mapping')
    default:
      if (!errorMessage) {
        return errorMessage
      }
      if (errorMessage.match(/^[0-9]+: An Account in E-conomic Used by Salary is Barred$/)) {
        const accountNumber = errorMessage.split(':')[0]
        return t('error.message.an_account_in_e_conomic_used_by_salary_is_barred', { accountNumber })
      }
      if (errorMessage.match(/^[0-9]+: Departmental distribution in E-conomic Used by Salary is Barred$/)) {
        const departmentalDistribution = errorMessage.split(':')[0]
        return t('error.message.departmental_distribution_in_e_conomic_used_by_salary_is_barred', {
          departmentalDistribution,
        })
      }
      if (errorMessage.match(/^.+: Period in E-conomic Used by Salary is Barred$/)) {
        return t('error.message.period_in_e_conomic_used_by_salary_is_barred')
      }
      if (errorMessage.match(/^[0-9]+: Accounting Integration Mapped Account Missing$/)) {
        const accountNumber = errorMessage.split(':')[0]
        return t('error.message.accounting_integration_mapped_account_missing', { accountNumber })
      }
      return errorMessage
  }
}

/**
 * Formats an error message with details based on an unhandled type.
 *
 * Returns null if it does not know the message, the calling function should handle this case itself
 * @param errorMessage
 * @param details
 */
export function formatErrorMessageDetails(errorMessage: string, details?: string[]) {
  switch (errorMessage) {
    case 'Employee Time Registration Invalid':
      if (details && details.length > 0) {
        switch (details[0]) {
          case 'Old DK Vacation cannot be registered after 2020-08-31':
            return t('error.details.time_registration_invalid.dk_old_vacation_before_20200831')
          case 'Leave registration cannot be on vacation day':
            return t('error.details.time_registration_invalid.no_leave_on_vacation_day')
          default:
            if (details[0].match(/Contract ([0-9a-z-]+) does not allow this salary type: ([0-9a-z-]+)/)) {
              return t('error.details.time_registration_invalid.contract_does_not_allow_type')
            }
        }
      }
      return t('error.details.time_registration_invalid.other')
    case 'Invalid Employee Data':
      return (
        <div>
          {t('error.invalid_employee_data')}
          {details &&
            details.map((detail, i) => {
              return <div key={i}>{formatInvalidEmployeeDataDetail(detail)}</div>
            })}
        </div>
      )
    case 'Login throttled':
      if (details && details.length > 0) {
        return t('error.message.login_throttled.details', {
          time: formatDate(details[0].replace('Next try: ', ''), 'HH:mm'),
        })
      }
      return t('error.message.login_throttled')
    case 'Employee Contract Has Orphaned Salary Parts':
      return t('error.details.contract_update.orphaned_salary_parts')
    default: {
      const newMessage = formatErrorMessage(errorMessage)
      if (newMessage === errorMessage) {
        // because formatErrorMessage returns itself, if it does not know the error message
        // we want to return null instead
        return null
      }
      return newMessage
    }
  }
}

export function formatError(e: Error) {
  if (!e) {
    logError('No error provided!')
    return ''
  }
  if (e instanceof RequestError) {
    switch (e.type) {
      case 'CompanyAlreadyExists':
        return t('error.base.company_already_exists')
      case 'CompanyFeatureMissing':
        return t('error.base.company_feature_missing')
      case 'EmailAlreadyExists':
        return t('error.base.email_already_exists')
      case 'InternalServerError':
        return t('error.base.internal_server_error')
      case 'WrongPassword':
        return t('error.base.wrong_password')
      case 'PricingPackageLocked':
        return t('error.base.pricing_package_locked')
      case 'GatewayTimeout':
        return t('error.base.gateway_timeout')
      case 'BadGateway':
        return t('error.base.bad_gateway')
      case '413':
        return t('error.base.request_too_large')
      default: {
        const returned = formatErrorMessageDetails(e.message, e.details)
        if (!returned) {
          if (process.env.NODE_ENV !== 'test') {
            logWarning('Missing error handling: ' + e.message + ' (' + e.type + ')')
          }
          return t('error.base.unknown')
        }
        return returned
      }
    }
  }
  if (e instanceof Error) {
    return formatErrorMessage(e.message)
  }
  return ''
  // return (
  //   <div>
  //     {e.split('\n').map((line, i) => {
  //       return <div key={i}>{line}</div>
  //     })}
  //   </div>
  // )
}

export function formatValidationErrors() {
  return t('form.validation.fill_in_fields_correctly')
}

/**
 * Convert a stored error (e.g. from AsynchronousTask) to an actual error
 * @param e
 */
export function convertStoredErrorToError(e?: StoredError): Error | null {
  if (!e || !e.error) {
    return null
  }
  return new RequestError(e.error.message, e.error.type, e.error.details || [])
}

export function formatStoredError(e?: StoredError) {
  const err = convertStoredErrorToError(e)
  if (!err) {
    return null
  }
  return formatError(err)
}

export function compareError(a: Error | null, b: Error | null): boolean {
  if (!a && !b) {
    return true
  }
  if (!a || !b) {
    return false
  }
  return a.message == b.message && a.name === b.name
}
