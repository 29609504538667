import { addMonths, max, startOfMonth, subYears } from 'date-fns'
import { List } from 'immutable'
import React, { ReactElement, useEffect, useState } from 'react'
import { usePrevious } from 'react-use'

import { addAlertSignature } from '../../../actions/alerts'
import CoarseSalaryRegistration, {
  CoarseSalaryRegistrationMutableFields,
} from '../../../model/coarseSalaryRegistration'
import Contract from '../../../model/contract'
import Employee from '../../../model/employee'
import { SalaryDefinition } from '../../../model/remuneration'
import SalaryCycle from '../../../model/salaryCycle'
import SalaryPeriod from '../../../model/salaryPeriod'
import SalaryType from '../../../model/salaryType'
import { CoarseSalaryRegistrationReducer } from '../../../reducers/coarseSalaryRegistrations'
import { ContractReducer } from '../../../reducers/contracts'
import { regularComponentDidUpdate } from '../../../utils/component-utils'
import { formatAPIDate, formatDate, getDate, isSameOrAfter, isSameOrBefore } from '../../../utils/date-utils'
import { getValidFrom } from '../../../utils/employment-utils'
import { formatError } from '../../../utils/error-utils'
import { getSalaryCycle } from '../../../utils/salary-cycle-utils'
import { getCurrentPeriodFromDispositionDate } from '../../../utils/salary-period-utils'
import { t } from '../../../utils/translation-utils'
import Form from '../../antd/form'
import Select from '../../antd/select'
import Alert from '../../elements/alert'
import Col from '../../elements/grid/col'
import Row from '../../elements/grid/row'
import LoadingOverlay from '../../widgets/LoadingOverlay'
import CoarseSalaryRegistrationForm, { ResultFields } from './CoarseSalaryRegistrationForm'

type Props = {
  employee: Employee
  contract: Contract
  contracts: ContractReducer
  coarseSalaryRegistrations: CoarseSalaryRegistrationReducer
  salaryCycles: List<SalaryCycle>
  salaryCycle: SalaryCycle
  salaryTypes: List<SalaryType>

  addAlert: addAlertSignature
  updateCoarseSalaryRegistrationBulk: (
    employeeID: string,
    salaryRegistrations: CoarseSalaryRegistrationMutableFields[]
  ) => void
  updateContract: (contract: Contract) => void
  addContract: (contract: Contract) => void
}

export default function CoarseSalaryRegistrationTab(props: Props): ReactElement | null {
  const [salaryPeriodID, setSalaryPeriodID] = useState<string | undefined>(() => {
    const salaryCycle = getSalaryCycle(props.salaryCycles.toArray(), props.contract.salaryCycleID)
    const period = getCurrentPeriodFromDispositionDate(props.salaryCycle.salaryPeriods)
    return salaryCycle && period ? period.id : undefined
  })
  const [error, setError] = useState<Error | null>(null)

  const { coarseSalaryRegistrations, employee, addAlert } = props
  const previousCoarseSalaryRegistrations = usePrevious(coarseSalaryRegistrations)

  useEffect(() => {
    if (
      previousCoarseSalaryRegistrations &&
      previousCoarseSalaryRegistrations.saving &&
      !coarseSalaryRegistrations.saving
    ) {
      if (!coarseSalaryRegistrations.error) {
        addAlert('success', t('salary_registrations_tab.coarse.alert.success', { name: employee.name }), { timeout: 5 })
      }
    }

    regularComponentDidUpdate(coarseSalaryRegistrations.error, error, setError)
  }, [previousCoarseSalaryRegistrations, coarseSalaryRegistrations, error, addAlert, employee])

  const getSalaryPeriods = (): SalaryPeriod[] => {
    const contract = props.contract
    let useFirst = false
    const salaryCycles = props.salaryCycles.filter(
      (item) => item.id === contract.salaryCycleID && item.frequency === 'Monthly'
    )
    if (salaryCycles.size > 0) {
      useFirst = true
    }
    return props.salaryCycle.salaryPeriods
      .map((salaryPeriod) => ({
        ...salaryPeriod,
        first: useFirst ? formatAPIDate(startOfMonth(getDate(salaryPeriod.end))) : salaryPeriod.start,
      }))
      .filter((salaryPeriod) => {
        const date = max([getDate(salaryPeriod.dispositionDate), getDate(salaryPeriod.end)])
        return isSameOrAfter(date, subYears(getDate(), 1)) && isSameOrBefore(date, addMonths(getDate(), 1))
      })
      .sort((a, b) => b.start.localeCompare(a.start))
  }

  const getPeriod = (): SalaryPeriod | undefined => {
    return props.salaryCycle.salaryPeriods.find((period) => period.id === salaryPeriodID)
  }

  const handleSubmit = (values: ResultFields) => {
    const employee = props.employee
    const contract = props.contract
    const remuneration = contract.remuneration
    if (!remuneration) {
      return
    }

    interface Registration {
      quantity: number
      rate: number
      salaryDefinition: SalaryDefinition
      salaryTypeID: string
    }

    const registrations: Registration[] = []
    Object.keys(values.registrations).forEach((salaryTypeID) => {
      const salary = remuneration.salary.find((salary) => salary.salaryTypeID === salaryTypeID)
      if (!salary) {
        return
      }
      registrations.push({
        quantity: values.registrations[salaryTypeID].quantity,
        rate: values.registrations[salaryTypeID].rate,
        salaryDefinition: salary,
        salaryTypeID: salaryTypeID,
      })
    })

    // check if any rates were changed
    if (registrations.some((registration) => registration.rate !== registration.salaryDefinition.rate)) {
      remuneration.salary.map((salary) => {
        const registration = registrations.find((registration) => registration.salaryTypeID === salary.salaryTypeID)
        if (!registration || salary.rate === registration.rate) {
          return salary
        }
        salary.rate = registration.rate
        return salary
      })
      const { validFrom, canUpdate } = getValidFrom(props.employee, contract)
      if (canUpdate) {
        props.updateContract(contract)
      } else {
        contract.validFrom = formatAPIDate(validFrom)
        props.addContract(contract)
      }
    }

    const period = getPeriod()
    if (!period) {
      return
    }
    const salaryRegistrations: CoarseSalaryRegistrationMutableFields[] = []
    registrations.forEach((registration) => {
      salaryRegistrations.push({
        employeeID: employee.id,
        salaryPeriodID: period.id,
        salaryTypeID: registration.salaryTypeID,
        quantity: registration.quantity,
      })
    })
    props.updateCoarseSalaryRegistrationBulk(employee.id, salaryRegistrations)
  }

  const canModifySupplements = true

  const getCoarseSalaryRegistrations = (): CoarseSalaryRegistration[] => {
    const period = getPeriod()
    if (!period) {
      return []
    }
    return props.coarseSalaryRegistrations.coarseSalaryRegistrations
      .filter(
        (registration) => registration.employeeID === props.employee.id && registration.salaryPeriodID === period.id
      )
      .toArray()
  }

  const period = getPeriod()

  return (
    <div>
      {error && <Alert message={formatError(error)} type="error" showIcon />}
      <Row>
        <Col span={12}>
          <Form.Item style={{ marginBottom: 10 }}>
            <label style={{ marginBottom: 0 }}>{t('salary_registrations_tab.coarse.select_period')}</label>
            <Select
              value={salaryPeriodID}
              dropdownMatchSelectWidth={false}
              onChange={(e: string) => setSalaryPeriodID(e)}
            >
              {getSalaryPeriods().map((salaryPeriod) => {
                return (
                  <Select.Option key={salaryPeriod.id} value={salaryPeriod.id}>
                    {t('salary_registrations_tab.coarse.period_range', {
                      from: formatDate(salaryPeriod.start),
                      to: formatDate(salaryPeriod.end),
                    })}
                  </Select.Option>
                )
              })}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      {canModifySupplements && period && (
        <CoarseSalaryRegistrationForm
          key={salaryPeriodID}
          employee={props.employee}
          contract={props.contract}
          period={period}
          coarseSalaryRegistrations={getCoarseSalaryRegistrations()}
          salaryTypes={props.salaryTypes}
          addAlert={props.addAlert}
          onSubmit={handleSubmit}
        />
      )}
      {!canModifySupplements && (
        <div>
          {props.coarseSalaryRegistrations.coarseSalaryRegistrations
            .filter((registration) => registration.employeeID === props.employee.id)
            .map((registration) => {
              if (!props.contract.remuneration) {
                return null
              }
              const salary = props.contract.remuneration.salary.find(
                (salary) => salary.salaryTypeID === registration.salaryTypeID
              )
              if (!salary) {
                return null
              }
              return (
                <Row>
                  <Col span={12}>{registration.quantity}</Col>
                  <Col span={12}>{t('salary_registrations_tab.coarse.of_rate', { rate: salary.rate })}</Col>
                </Row>
              )
            })}
        </div>
      )}
      {(props.contracts.saving || props.coarseSalaryRegistrations.saving) && <LoadingOverlay />}
    </div>
  )
}
