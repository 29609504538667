import { List, Record } from 'immutable'

import ActionTypes from '../constants/action-types'
import CarAllowance from '../model/carAllowance'
import { ReducerAction } from '../utils/reducer-utils'

export interface CarAllowanceReducer {
  companyID: string | undefined
  employeeID: string | undefined
  loading: boolean
  loaded: boolean
  saving: boolean
  carAllowances: List<CarAllowance>
  error: Error | null
}

const Params = Record<CarAllowanceReducer>({
  companyID: undefined,
  employeeID: undefined,
  loading: false,
  loaded: false,
  saving: false,
  carAllowances: List<CarAllowance>(),
  error: null,
})

const comparator = (a: CarAllowance, b: CarAllowance) => {
  return b.date.localeCompare(a.date, 'da-dk')
}

export interface CarAllowanceAction extends ReducerAction {
  companyID?: string
  employeeID?: string
  carAllowances?: CarAllowance[]
  carAllowance?: CarAllowance
  carAllowanceID?: string
  carAllowanceIDs?: string[]
  approved?: boolean
}

export default (
  state: Record<CarAllowanceReducer> = Params(),
  action: CarAllowanceAction = { type: '' }
): Record<CarAllowanceReducer> => {
  // keep track of the active company
  if (action.type === ActionTypes.COMPANIES_SETTING_ACTIVE || action.type === ActionTypes.COMPANIES_LOADED) {
    return Params({
      companyID: action.companyID,
    })
  }

  let idx = -1
  let carAllowances = state.get('carAllowances')
  switch (action.type) {
    case ActionTypes.CAR_ALLOWANCES_LOADING:
      return Params({
        companyID: action.companyID,
        employeeID: action.employeeID,
        loading: true,
      })
    case ActionTypes.CAR_ALLOWANCES_LOADED:
      return state
        .set('loading', false)
        .set('loaded', true)
        .set('carAllowances', List<CarAllowance>(action.carAllowances).sort(comparator))
        .set('error', null)
    case ActionTypes.CAR_ALLOWANCES_LOAD_FAILED:
      return state
        .set('loading', false)
        .set('loaded', false)
        .set('error', action.error || null)

    case ActionTypes.CAR_ALLOWANCES_CREATING:
      return state.set('saving', true).set('error', null)
    case ActionTypes.CAR_ALLOWANCE_CREATED:
      if (!action.carAllowance) {
        return state
      }
      if (!action.companyID) {
        // only process actions for the active employee (if any)
        if (state.get('employeeID')) {
          if (action.employeeID !== state.get('employeeID')) {
            return state
          }
        } else if (state.get('companyID') !== action.carAllowance.companyID) {
          // otherwise check against company
          return state
        }
      }
      idx = state.get('carAllowances').findIndex((item) => !!action.carAllowance && item.id === action.carAllowance.id)
      if (idx !== -1) {
        return state
          .set('saving', false)
          .set('carAllowances', state.get('carAllowances').set(idx, action.carAllowance).sort(comparator))
      }
      return state
        .set('saving', false)
        .set('carAllowances', state.get('carAllowances').push(action.carAllowance).sort(comparator))
    case ActionTypes.CAR_ALLOWANCES_CREATED:
      if (!action.carAllowances) {
        return state
      }
      action.carAllowances.forEach((carAllowance) => {
        if (!action.companyID) {
          // only process actions for the active employee
          if (carAllowance.employeeID !== state.get('employeeID')) {
            return
          }
        }
        idx = carAllowances.findIndex((item) => item.id === carAllowance.id)
        if (idx !== -1) {
          carAllowances = carAllowances.set(idx, carAllowance)
        } else {
          carAllowances = carAllowances.push(carAllowance)
        }
      })
      return state.set('saving', false).set('carAllowances', carAllowances.sort(comparator))
    case ActionTypes.CAR_ALLOWANCES_CREATE_FAILED:
      return state.set('saving', false).set('error', action.error || null)

    case ActionTypes.CAR_ALLOWANCE_APPROVING:
      return state.set('saving', true).set('error', null)
    case ActionTypes.CAR_ALLOWANCE_APPROVED:
      if (typeof action.approved !== 'boolean') {
        return state
      }
      return state.set('saving', false).set(
        'carAllowances',
        state
          .get('carAllowances')
          .map((allowance) => {
            if (!action.carAllowanceIDs) {
              return allowance
            }
            if (action.carAllowanceIDs.indexOf(allowance.id) !== -1) {
              allowance.approved = !!action.approved
            }
            return allowance
          })
          .sort(comparator)
      )
    case ActionTypes.CAR_ALLOWANCE_APPROVE_FAILED:
      return state.set('saving', false).set('error', action.error || null)

    case ActionTypes.CAR_ALLOWANCE_UPDATING:
      return state.set('saving', true).set('error', null)
    case ActionTypes.CAR_ALLOWANCE_UPDATED:
      if (!action.carAllowance) {
        return state
      }
      if (action.employeeID && action.carAllowanceID) {
        idx = state
          .get('carAllowances')
          .findIndex((item) => item.employeeID === action.employeeID && item.id === action.carAllowanceID)
      }
      if (idx === -1) {
        return state
      }
      return state
        .set('saving', false)
        .set('carAllowances', state.get('carAllowances').set(idx, action.carAllowance).sort(comparator))
    case ActionTypes.CAR_ALLOWANCE_UPDATE_FAILED:
      return state.set('saving', false).set('error', action.error || null)

    case ActionTypes.CAR_ALLOWANCE_DELETING:
      return state.set('saving', true).set('error', null)
    case ActionTypes.CAR_ALLOWANCE_DELETED:
      if (action.carAllowanceID) {
        idx = state.get('carAllowances').findIndex((item) => item.id === action.carAllowanceID)
      }
      if (idx === -1) {
        return state.set('saving', false)
      }
      return state.set('saving', false).set('carAllowances', state.get('carAllowances').delete(idx))
    case ActionTypes.CAR_ALLOWANCE_DELETE_FAILED:
      return state.set('saving', false).set('error', action.error || null)

    case ActionTypes.USER_LOGGED_OUT:
      return Params()
    default:
      return state
  }
}
