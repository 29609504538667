import { List } from 'immutable'
import React, { ReactElement, ReactNode, useState } from 'react'
import { Link } from 'react-router'

import { addAlertSignature, removeAlertSignature } from '../../actions/alerts'
import paths from '../../constants/paths'
import PDFPreviewTypes from '../../constants/pdf-preview-types'
import { AccountingAccount } from '../../model/accountingIntegration'
import AsynchronousTask from '../../model/asynchronousTask'
import Company from '../../model/company'
import ExpenseCategory from '../../model/expenseCategory'
import ReimbursementVoucher from '../../model/reimbursementVoucher'
import { DateFormat } from '../../model/types'
import Voucher from '../../model/voucher'
import { AlertReducer } from '../../reducers/alerts'
import { UserReducer } from '../../reducers/user'
import { formatDate, getDate, isSameOrBefore } from '../../utils/date-utils'
import { formatStoredError } from '../../utils/error-utils'
import { t } from '../../utils/translation-utils'
import Modal from '../antd/modal'
import Table from '../antd/table'
import Button from '../elements/button'
import Card from '../elements/card'
import Checkbox from '../elements/checkbox'
import Icon from '../elements/Icon'
import Title from '../elements/Title'
import TitleMenu from '../elements/TitleMenu'
import Tooltip from '../elements/tooltip'
import Alerts from '../widgets/Alerts'
import DumbLink from '../widgets/DumbLink'
import jsBrowserHistory from '../widgets/jsBrowserHistory'
import ReportButton from '../widgets/ReportButton'
import VoucherDateModal from './VoucherDateModal'
import VoucherLinesModal from './VoucherLinesModal'

import './Vouchers.css'

type Props = {
  alerts: AlertReducer
  asynchronousTasks: List<AsynchronousTask>
  user: UserReducer
  company: Company
  vouchers: List<Voucher>
  reimbursementVouchers: List<ReimbursementVoucher>
  expenseCategories: List<ExpenseCategory>
  accounts: List<AccountingAccount>

  addAlert: addAlertSignature
  removeAlert: removeAlertSignature
  rebookVoucher: (id: string) => void
  rebookReimbursementVouchers: (ids: string[]) => void
  changeVoucherDate: (id: string, date: DateFormat) => Promise<Voucher | void>
}

export default function Vouchers(props: Props): ReactElement | null {
  const [modalKey, setModalKey] = useState(1)
  const [showVoucher, setShowVoucher] = useState<string | boolean>(false)
  const [showReimbursementVoucher, setShowReimbursementVoucher] = useState<string | boolean>(false)
  const [showVoucherDate, setShowVoucherDate] = useState<string | boolean>(false)
  type TypeFilter = {
    reimbursementVoucher: boolean
    transfer: boolean
    payroll: boolean
  }
  const [typeFilter, setTypeFilter] = useState<TypeFilter>({
    reimbursementVoucher: true,
    transfer: true,
    payroll: true,
  })
  const [showOnlyFailed, setShowOnlyFailed] = useState(false)

  type internalType = 'reimbursementVoucher' | 'transfer' | 'payroll'

  type VoucherRow = {
    key: string
    id: string
    canRebook: boolean
    canChangeDate: boolean
    needsConfirmation: boolean
    type: string
    internalType: internalType
    status: string
    failed: boolean
    date: string
    internalDate: DateFormat
    isReimbursement: boolean
    payRollID?: string
    error?: ReactNode
  }

  const showVoucherVisibility = (voucher: VoucherRow) => {
    setModalKey((prev) => prev + 1)
    if (voucher.isReimbursement) {
      setShowReimbursementVoucher(voucher.id)
    } else {
      setShowVoucher(voucher.id)
    }
  }

  const showDateVisibility = (voucher: VoucherRow) => {
    setModalKey((prev) => prev + 1)
    if (voucher.isReimbursement) {
      return // not implemented
    } else {
      setShowVoucherDate(voucher.id)
    }
  }

  const hideVoucherVisibility = () => {
    setShowVoucher(false)
    setShowReimbursementVoucher(false)
  }

  const hideVoucherDateVisibility = () => {
    setShowVoucherDate(false)
  }

  const rebook = (voucher: VoucherRow) => {
    return (e: React.MouseEvent) => {
      e.preventDefault()
      const rebook = () => {
        if (voucher.isReimbursement) {
          props.rebookReimbursementVouchers([voucher.id])
        } else {
          props.rebookVoucher(voucher.id)
        }
      }
      if (voucher.needsConfirmation) {
        if (window.confirm(t('common.are_you_sure'))) {
          rebook()
        }
      } else {
        rebook()
      }
    }
  }

  const columns = [
    {
      title: t('vouchers.table.header.status'),
      dataIndex: '',
      key: 'xStatus',
      render: (voucher: VoucherRow) => {
        let status: ReactNode = voucher.status
        if (voucher.error) {
          status = <Tooltip title={voucher.error}>{status}</Tooltip>
        }
        if (voucher.canRebook) {
          return (
            <>
              {status} (
              <DumbLink onClick={rebook(voucher)} type="standard">
                {t('vouchers.table.rebook')}
              </DumbLink>
              )
            </>
          )
        }
        return status
      },
    },
    { title: t('vouchers.table.header.type'), dataIndex: 'type', key: 'type' },
    {
      title: t('vouchers.table.header.date'),
      dataIndex: '',
      key: 'xDate',
      render: (voucher: VoucherRow) => {
        if (voucher.canChangeDate) {
          return (
            <>
              {voucher.date} (
              <DumbLink onClick={() => showDateVisibility(voucher)} type="standard">
                {t('vouchers.table.change_date')}
              </DumbLink>
              )
            </>
          )
        }
        return voucher.date
      },
    },
    {
      title: '',
      dataIndex: '',
      key: 'x1',
      width: 1,
      render: (voucher: VoucherRow) => (
        <span className="voucher-row">
          <DumbLink
            onClick={(e: React.MouseEvent) => {
              e.preventDefault()
              showVoucherVisibility(voucher)
            }}
          >
            <Button title={t('vouchers.table.actions.details.tooltip')}>
              <Icon type="file" color="grey" /> {t('vouchers.table.actions.details')}
            </Button>
          </DumbLink>
          &nbsp;&nbsp;
          {!voucher.isReimbursement &&
            (props.user.userType === 'Admin' || props.user.userType === 'Support' ? (
              <DumbLink
                onClick={(e: React.MouseEvent) => {
                  e.preventDefault()
                  jsBrowserHistory.push(
                    '/' + paths.PDF_PREVIEW + '/' + PDFPreviewTypes.ACCOUNTING_VOUCHER + '/' + voucher.id
                  )
                }}
              >
                <Button title={t('vouchers.table.actions.pdf.tooltip')}>
                  <Icon type="download" color="grey" /> {t('vouchers.table.actions.pdf')}
                </Button>
              </DumbLink>
            ) : (
              <Link
                to={'/' + paths.PDF_PREVIEW + '/' + PDFPreviewTypes.ACCOUNTING_VOUCHER + '/' + voucher.id}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button title={t('vouchers.table.actions.pdf.tooltip')}>
                  <Icon type="download" color="grey" /> {t('vouchers.table.actions.pdf')}
                </Button>
              </Link>
            ))}
          &nbsp;&nbsp;
          <ReportButton
            state={1}
            addAlert={props.addAlert}
            asynchronousTasks={props.asynchronousTasks}
            type={'CSV'}
            getFields={() => ({
              type: 'Regular',
              payRollID: voucher.payRollID,
              voucherID: !voucher.isReimbursement && !voucher.payRollID ? voucher.id : undefined,
              reimbursementVoucherID: voucher.isReimbursement ? voucher.id : undefined,
              companyID: props.company.id,
              from: '0001-01-01',
              to: '0001-01-01',
              report: voucher.payRollID
                ? 'PayRollAccounting'
                : voucher.isReimbursement
                ? 'ReimbursementVoucherAccounting'
                : 'VoucherAccounting',
            })}
            text={t('vouchers.table.actions.csv')}
            icon={<Icon type="download" color="grey" />}
          />
        </span>
      ),
    },
  ]

  const getVoucherRows = (): VoucherRow[] => {
    const vouchers = props.vouchers
      .map((voucher): VoucherRow => {
        let status = ''
        switch (voucher.state) {
          case 'Pending':
            if (voucher.approved) {
              if (isSameOrBefore(getDate(voucher.date), getDate())) {
                status = t('vouchers.table.status.pending.within_15_minutes')
              } else {
                status = t('vouchers.table.status.pending.at_date', { date: formatDate(voucher.date) })
              }
            } else {
              status = t('vouchers.table.status.pending.unapproved')
            }
            break
          case 'Booked':
            if (voucher.automated) {
              status = t('vouchers.table.status.booked.automated')
            } else {
              status = t('vouchers.table.status.booked.manual')
            }
            break
          case 'Daybooked':
            status = t('vouchers.table.status.daybooked')
            break
          case 'Booking Failed':
            status = t('vouchers.table.status.booking_failed')
            break
          case 'Manual':
            status = t('vouchers.table.status.manual')
            break
          default:
            break
        }
        let type = t('vouchers.table.type.transfer')
        let internalType: internalType = 'transfer'
        if (voucher.type === 'PayRoll') {
          type = t('vouchers.table.type.pay_roll')
          internalType = 'payroll'
        }
        return {
          key: voucher.id,
          id: voucher.id,
          date: formatDate(voucher.date),
          internalDate: voucher.date,
          status: status,
          failed: voucher.state === 'Booking Failed',
          error: formatStoredError(voucher.error) ?? undefined,
          type,
          internalType,
          canRebook: voucher.rebookable,
          canChangeDate: voucher.canChangeDate,
          needsConfirmation: voucher.state === 'Booked' || voucher.state === 'Daybooked',
          payRollID: voucher.payRollID,
          isReimbursement: false,
        }
      })
      .toArray()
    const reimbursementVouchers = props.reimbursementVouchers
      .filter((voucher) => voucher.approvalState === 'Approved')
      .map((voucher): VoucherRow => {
        let status = ''
        switch (voucher.bookingState) {
          case 'Pending':
            status = t('vouchers.table.status.pending.unapproved')
            break
          case 'Ready':
            status = t('vouchers.table.status.pending.within_15_minutes')
            break
          case 'Booked':
            status = t('vouchers.table.status.booked.automated')
            break
          case 'Daybooked':
            status = t('vouchers.table.status.daybooked')
            break
          case 'Manual':
            status = t('vouchers.table.status.manual')
            break
          case 'Failed':
            status = t('vouchers.table.status.booking_failed')
            break
        }
        return {
          key: voucher.id,
          id: voucher.id,
          date: formatDate(voucher.receiptDate || voucher.createdAt),
          internalDate: voucher.receiptDate || voucher.createdAt,
          status,
          failed: voucher.bookingState === 'Failed',
          error: formatStoredError(voucher.error) ?? undefined,
          type: t('vouchers.table.type.reimbursement'),
          internalType: 'reimbursementVoucher',
          canRebook:
            voucher.bookingState === 'Booked' ||
            voucher.bookingState === 'Daybooked' ||
            voucher.bookingState === 'Failed',
          canChangeDate: false,
          needsConfirmation: voucher.bookingState === 'Booked' || voucher.bookingState === 'Daybooked',
          isReimbursement: true,
        }
      })
    return [...vouchers, ...reimbursementVouchers]
      .filter(
        (v) =>
          ((v.internalType === 'payroll' && typeFilter.payroll) ||
            (v.internalType === 'transfer' && typeFilter.transfer) ||
            (v.internalType === 'reimbursementVoucher' && typeFilter.reimbursementVoucher)) &&
          (!showOnlyFailed || v.failed)
      )
      .sort((a, b) => b.internalDate.localeCompare(a.internalDate))
  }

  const voucher = props.vouchers.find((voucher) => voucher.id === showVoucher)
  const reimbursementVoucher = props.reimbursementVouchers.find((voucher) => voucher.id === showReimbursementVoucher)
  const voucherDate = props.vouchers.find((voucher) => voucher.id === showVoucherDate)
  return (
    <div className="accounting-vouchers">
      <Alerts alerts={props.alerts} removeAlert={props.removeAlert} />
      <TitleMenu>
        {props.reimbursementVouchers.size > 0 && (
          <Checkbox
            onChange={() =>
              setTypeFilter((filter) => ({ ...filter, reimbursementVoucher: !filter.reimbursementVoucher }))
            }
            checked={typeFilter.reimbursementVoucher}
          >
            {t('vouchers.header.filter.reimbursement_vouchers')}
          </Checkbox>
        )}
        <Checkbox
          onChange={() => setTypeFilter((filter) => ({ ...filter, transfer: !filter.transfer }))}
          checked={typeFilter.transfer}
        >
          {t('vouchers.header.filter.transfer')}
        </Checkbox>
        <Checkbox
          onChange={() => setTypeFilter((filter) => ({ ...filter, payroll: !filter.payroll }))}
          checked={typeFilter.payroll}
        >
          {t('vouchers.header.filter.payroll')}
        </Checkbox>
        <Checkbox onChange={() => setShowOnlyFailed((prev) => !prev)} checked={showOnlyFailed}>
          {t('vouchers.header.filter.only_failed')}
        </Checkbox>
        <Link to={'/' + paths.INTEGRATIONS + '/' + paths.ACCOUNTING}>
          <Button>{t('vouchers.header.back')}</Button>
        </Link>
      </TitleMenu>
      <Title>{t('vouchers.header.title')}</Title>

      <Card>
        <Table columns={columns} dataSource={getVoucherRows()} size="small" pagination={false} />

        <Modal
          key={`voucher-${modalKey}`}
          visible={!!showVoucher || !!showReimbursementVoucher}
          onOk={() => hideVoucherVisibility()}
          onCancel={() => hideVoucherVisibility()}
          width={788}
          footer={null}
        >
          <VoucherLinesModal
            visible={!!showVoucher}
            voucher={voucher}
            reimbursementVoucher={reimbursementVoucher}
            expenseCategories={props.expenseCategories}
            accounts={props.accounts}
          />
        </Modal>

        <Modal
          key={`date-${modalKey}`}
          visible={!!showVoucherDate}
          onOk={() => hideVoucherDateVisibility()}
          onCancel={() => hideVoucherDateVisibility()}
          width={450}
          footer={null}
        >
          <VoucherDateModal
            visible={!!showVoucher}
            voucher={voucherDate}
            changeVoucherDate={props.changeVoucherDate}
            closeModal={hideVoucherDateVisibility}
          />
        </Modal>
      </Card>
    </div>
  )
}
