import { List } from 'immutable'
import React, { ReactElement, useCallback, useEffect, useState } from 'react'
import { usePrevious } from 'react-use'

import { addAlertSignature } from '../../../actions/alerts'
import Company from '../../../model/company'
import CostCenter from '../../../model/costCenter'
import Department from '../../../model/department'
import Employee from '../../../model/employee'
import SalaryRegistration, { SalaryRegistrationMutableFields } from '../../../model/salaryRegistration'
import SalaryType from '../../../model/salaryType'
import { SalaryRegistrationReducer } from '../../../reducers/salaryRegistrations'
import { formatDate } from '../../../utils/date-utils'
import { formatSavingText } from '../../../utils/loading-utils'
import { formatCurrency, formatDisplayNumber } from '../../../utils/number-utils'
import { getSalaryType } from '../../../utils/salary-type-utils'
import { t, translateGroupTitle } from '../../../utils/translation-utils'
import Modal from '../../antd/modal'
import Table from '../../antd/table'
import Button from '../../elements/button'
import Col from '../../elements/grid/col'
import Row from '../../elements/grid/row'
import Icon from '../../elements/Icon'
import Tooltip from '../../elements/tooltip'
import DumbLink from '../../widgets/DumbLink'
import LoadingOverlay from '../../widgets/LoadingOverlay'
import DetailedSalaryRegistrationEdit from './DetailedSalaryRegistrationEdit'

type Props = {
  company: Company
  employee: Employee
  costCenters: List<CostCenter>
  departments: List<Department>
  canEditObjects: boolean
  canApproveObjects: boolean
  salaryRegistrations: SalaryRegistrationReducer
  salaryTypes: List<SalaryType>

  addAlert: addAlertSignature
  createSalaryRegistration: (registration: SalaryRegistrationMutableFields) => void
  approveSalaryRegistrations: (ids: string[]) => void
  unapproveSalaryRegistrations: (ids: string[]) => void
  updateSalaryRegistration: (registration: SalaryRegistrationMutableFields) => void
  deleteSalaryRegistration: (id: string) => void
  deleteSalaryRegistrationBulk: (companyID?: string, employeeID?: string) => void
}

export default function DetailedSalaryRegistrationTab(props: Props): ReactElement | null {
  const [approving, setApproving] = useState<string[]>([])
  const [unapproving, setUnapproving] = useState<string[]>([])
  const [deleting, setDeleting] = useState<string[]>([])
  const [modalKey, setModalKey] = useState(1)
  const [editing, setEditing] = useState<string | boolean>(false)

  const { salaryRegistrations } = props
  const previousSalaryRegistrations = usePrevious(salaryRegistrations)

  const setEditVisibility = useCallback(
    (id: string | boolean) => {
      // Increment modalKey to create a new component
      setModalKey((prev) => prev + 1)
      setEditing(id)
    },
    [setModalKey, setEditing]
  )

  useEffect(() => {
    if (previousSalaryRegistrations && previousSalaryRegistrations.saving && !salaryRegistrations.saving) {
      if (!salaryRegistrations.error) {
        setEditVisibility(false)
      }
    }
  }, [previousSalaryRegistrations, salaryRegistrations, setEditVisibility])

  const approve = (salaryRegistration: SalaryRegistration) => {
    return (e: React.MouseEvent) => {
      e.preventDefault()
      setApproving((prev) => [...prev, salaryRegistration.id])
      setUnapproving((prev) => prev.filter((id) => id !== salaryRegistration.id))
      props.approveSalaryRegistrations([salaryRegistration.id])
    }
  }
  const unapprove = (salaryRegistration: SalaryRegistration) => {
    return (e: React.MouseEvent) => {
      e.preventDefault()
      setApproving((prev) => prev.filter((id) => id !== salaryRegistration.id))
      setUnapproving((prev) => [...prev, salaryRegistration.id])
      props.unapproveSalaryRegistrations([salaryRegistration.id])
    }
  }
  const remove = (salaryRegistration: SalaryRegistration) => {
    return (e: React.MouseEvent) => {
      e.preventDefault()
      if (window.confirm(t('common.are_you_sure'))) {
        setDeleting((prev) => [...prev, salaryRegistration.id])
        props.deleteSalaryRegistration(salaryRegistration.id)
      }
    }
  }

  type SalaryRegistrationRow = {
    key: string
    id: string
    approved: boolean
    date: string
    description: string
    quantity: string
    note: string
    settled: boolean
    immutable: boolean
    original: SalaryRegistration
  }

  const columns = [
    {
      title: t('salary_registrations_tab.detailed.table.header.approved'),
      key: 'x1',
      className: 'salary-registration-table-approved',
      render: (salaryRegistration: SalaryRegistrationRow) => {
        if (salaryRegistration.approved) {
          if (unapproving.indexOf(salaryRegistration.id) !== -1) {
            return t('salary_registrations_tab.detailed.table.saving')
          }
          return (
            <span>
              {t('salary_registrations_tab.detailed.table.approved.true')}
              {props.canApproveObjects && (
                <Tooltip title={t('salary_registrations_tab.detailed.table.remove_approval')}>
                  <span onClick={unapprove(salaryRegistration.original)} style={{ cursor: 'pointer' }}>
                    <Icon type="cross" color="grey" />
                  </span>
                </Tooltip>
              )}
            </span>
          )
        }
        if (approving.indexOf(salaryRegistration.id) !== -1) {
          return t('salary_registrations_tab.detailed.table.saving')
        }
        if (!props.canApproveObjects) {
          return t('salary_registrations_tab.detailed.table.approved.false')
        }
        return (
          <DumbLink onClick={approve(salaryRegistration.original)} type="standard">
            {t('salary_registrations_tab.detailed.table.approve')}
          </DumbLink>
        )
      },
    },
    { title: t('salary_registrations_tab.detailed.table.header.date'), dataIndex: 'date', key: 'date' },
    {
      title: t('salary_registrations_tab.detailed.table.header.description'),
      dataIndex: 'description',
      key: 'description',
    },
    { title: t('salary_registrations_tab.detailed.table.header.quantity'), dataIndex: 'quantity', key: 'quantity' },
    {
      title: t('salary_registrations_tab.detailed.table.header.note'),
      key: 'x2',
      className: 'employee-table-actions',
      render: (salaryRegistration: SalaryRegistrationRow) => {
        if (!salaryRegistration.note) {
          return null
        }
        return (
          <div>
            <Tooltip title={salaryRegistration.note}>
              <span>
                <Icon type="file" color="grey" />
              </span>
            </Tooltip>
          </div>
        )
      },
    },
    {
      title: '',
      key: 'x3',
      className: 'employee-table-actions',
      render: (salaryRegistration: SalaryRegistrationRow) => {
        if (deleting.indexOf(salaryRegistration.id) !== -1) {
          return null
        }
        if (salaryRegistration.settled) {
          return null
        }
        if (!props.canEditObjects) {
          return null
        }
        return (
          <div>
            <Tooltip title={t('salary_registrations_tab.detailed.table.actions.edit')}>
              <span onClick={() => setEditVisibility(salaryRegistration.id)} style={{ cursor: 'pointer' }}>
                <Icon type="edit" color="grey" />
              </span>
            </Tooltip>
            {!salaryRegistration.immutable && (
              <Tooltip title={t('salary_registrations_tab.detailed.table.actions.delete')}>
                <span onClick={remove(salaryRegistration.original)} style={{ cursor: 'pointer' }}>
                  <Icon type="cross" color="grey" />
                </span>
              </Tooltip>
            )}
          </div>
        )
      },
    },
  ]
  const getSalaryRegistrations = (): SalaryRegistrationRow[] => {
    return props.salaryRegistrations.salaryRegistrations
      .filter(
        (salaryRegistration) =>
          salaryRegistration.employeeID === props.employee.id &&
          salaryRegistration.state === 'Pending' &&
          !deleting.some((id) => id === salaryRegistration.id)
      )
      .map((salaryRegistration) => {
        let title: string | undefined = undefined
        const quantity = formatDisplayNumber(salaryRegistration.quantity)
        const salary =
          props.employee.activeContract && props.employee.activeContract.remuneration
            ? props.employee.activeContract.remuneration.salary.find(
                (salary) => salary.salaryTypeID === salaryRegistration.salaryTypeID
              )
            : undefined
        let rate: number | undefined = undefined
        if (salary) {
          title = translateGroupTitle(salary)
          rate = salary.rate
        }
        if (!title) {
          title = getSalaryType(props.salaryTypes.toArray(), salaryRegistration.salaryTypeID)?.title || ''
        }
        if (rate) {
          title = title + ' (' + formatCurrency(rate, 2) + ')'
        }
        return {
          key: salaryRegistration.id,
          id: salaryRegistration.id,
          approved: salaryRegistration.approved,
          date: formatDate(salaryRegistration.date),
          description: title || t('common.unknown'),
          quantity,
          note: salaryRegistration.note,
          settled: salaryRegistration.state === 'Settled',
          immutable: salaryRegistration.immutable,
          original: salaryRegistration,
        }
      })
      .toArray()
  }

  const approveAll = (e: React.MouseEvent) => {
    e.preventDefault()
    if (window.confirm(t('common.are_you_sure'))) {
      const registrations = getSalaryRegistrations()
        .filter((reg) => !reg.approved)
        .map((reg) => reg.original)
      setApproving((prev) => [...prev, ...registrations.map((reg) => reg.id)])
      props.approveSalaryRegistrations(registrations.map((reg) => reg.id))
    }
  }

  const hasUnapprovedSalaryRegistrations = (): boolean => {
    return getSalaryRegistrations().some((reg) => !reg.approved)
  }

  const bulkDelete = () => {
    if (window.confirm(t('salary_registrations_tab.detailed.delete_all_confirm'))) {
      const list = getSalaryRegistrations()
        .filter((reg) => !reg.approved)
        .map((reg) => reg.id)
      setDeleting((prev) => [...prev, ...list])
      props.deleteSalaryRegistrationBulk(undefined, props.employee.id)
    }
  }

  const salaryRegistrationRows = getSalaryRegistrations()
  return (
    <div>
      <Row>
        <Col span={24} className="salary-registration-buttons">
          {props.canEditObjects && salaryRegistrationRows.some((reg) => !reg.settled) && (
            <Button onClick={() => bulkDelete()} type="danger" style={{ width: '200px', marginRight: '10px' }}>
              <Icon type="cross" color="red" />
              {t('salary_registrations_tab.detailed.header.delete_all')}
            </Button>
          )}

          {props.canEditObjects && (
            <Button onClick={() => setEditVisibility(true)}>
              <Icon type="stopwatch" color="grey" />
              {t('salary_registrations_tab.detailed.header.add_registration')}
            </Button>
          )}

          {hasUnapprovedSalaryRegistrations() && props.canApproveObjects && (
            <Button onClick={approveAll} style={{ marginLeft: 20 }}>
              {t('salary_registrations_tab.detailed.header.approve_all')}
            </Button>
          )}
        </Col>
      </Row>

      <Table columns={columns} dataSource={salaryRegistrationRows} size="small" pagination={false} />
      {props.salaryRegistrations.saving && (
        <LoadingOverlay
          text={formatSavingText([
            { loading: props.salaryRegistrations.saving, text: t('loading.reducer.salary_registrations') },
          ])}
        />
      )}

      <Modal
        key={modalKey}
        visible={editing !== false}
        onOk={() => setEditVisibility(false)}
        onCancel={() => setEditVisibility(false)}
        width={376}
        footer={null}
      >
        <DetailedSalaryRegistrationEdit
          visible={editing !== false}
          company={props.company}
          employee={props.employee}
          costCenters={props.costCenters}
          departments={props.departments}
          salaryRegistrationID={typeof editing === 'boolean' ? undefined : editing}
          salaryRegistrations={props.salaryRegistrations}
          salaryTypes={props.salaryTypes}
          createSalaryRegistration={props.createSalaryRegistration}
          updateSalaryRegistration={props.updateSalaryRegistration}
        />
      </Modal>
    </div>
  )
}
